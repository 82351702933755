/**
Decodes an iframe message
Returns only the conversional key
 */
export default function parseMessage(message) {
    if (!message || !message.data || typeof message.data !== 'string') {
        return {};
    }
    try {
        if (message.data.startsWith("webpack"))
            return {};
        var parsedMessage = JSON.parse(message.data);
        return parsedMessage.conversional || {};
    }
    catch (error) {
        // eslint-disable-next-line no-console
        // This function receives all iframe messages, no matter if they really are sent from Conversional or not.
        // So, some invalid messages are natural to reach here.
        // Stopped logging to Sentry on Jan, 2024 => Discussion: https://roomtailors.slack.com/archives/C030C4GDP2B/p1702625130810709
        // captureException(error, {message})
        return {};
    }
}
