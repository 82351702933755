import defaultsDeep from "lodash/defaultsDeep";
import pick from "lodash/pick";
export var BROWSER_GEO_LOCATION_POSITION_ERRORS = {
    NOT_SUPPORTED: "NOT_SUPPORTED",
    POSITION_UNAVAILABLE: "POSITION_UNAVAILABLE",
    PERMISSION_DENIED: "PERMISSION_DENIED",
    TIMEOUT: "TIMEOUT",
};
export function askCurrentPosition(success, error) {
    if (!navigator.geolocation)
        return error({
            message: BROWSER_GEO_LOCATION_POSITION_ERRORS.NOT_SUPPORTED
        });
    navigator.geolocation.getCurrentPosition(function (position) {
        success(defaultsDeep({}, pick(position.coords, 'latitude', 'longitude')));
    }, function (positionError) {
        var _a;
        var errorMap = (_a = {},
            _a[positionError.POSITION_UNAVAILABLE] = BROWSER_GEO_LOCATION_POSITION_ERRORS.POSITION_UNAVAILABLE,
            _a[positionError.PERMISSION_DENIED] = BROWSER_GEO_LOCATION_POSITION_ERRORS.PERMISSION_DENIED,
            _a[positionError.TIMEOUT] = BROWSER_GEO_LOCATION_POSITION_ERRORS.TIMEOUT,
            _a);
        if (errorMap[positionError.code]) {
            return error({
                message: errorMap[positionError.code]
            });
        }
        return error({
            rawMessage: positionError.message
        });
    });
}
export function isFirefox() {
    try {
        return navigator.userAgent.includes('Firefox');
    }
    catch (e) {
        return false;
    }
}
