import PubSub from 'pubsub-js';
var MessageHub = /** @class */ (function () {
    function MessageHub() {
    }
    //TODO: Set immedaiteexceptions for dev env
    //PubSub.immediateExceptions = true;
    MessageHub.prototype.publish = function (type, payload) {
        if (payload === void 0) { payload = {}; }
        return PubSub.publish(type, payload);
    };
    MessageHub.prototype.subscribe = function (type, subscriber) {
        return PubSub.subscribe(type, subscriber);
    };
    MessageHub.prototype.unsubscribe = function (type, subscriber) {
        return PubSub.unsubscribe(type, subscriber);
    };
    MessageHub.prototype.unsubscribeFromAll = function (subscriber) {
        return PubSub.unsubscribe(subscriber);
    };
    MessageHub.prototype.getSubscriptions = function (type) {
        return PubSub.getSubscriptions(type);
    };
    return MessageHub;
}());
/** The Messagehub is a Singleton, always returng the same instance */
var hubInstance;
export default (function () {
    if (!hubInstance) {
        hubInstance = new MessageHub();
    }
    return hubInstance;
});
