export var DEV_ROOT = "http://localhost:7001/api";
export var GET_JOURNEY_PATH = '/journeys/:journeyId';
export var GET_QUESTIONNAIRE_PATH = '/questionnaires/:questionnaireId';
export var SEND_ANSWERS_PATH = '/questionnaires/';
export var GET_RESULT_PATH = '/presenters/:token';
export var GET_RESULT_PATH_DEV = "".concat(DEV_ROOT).concat(GET_RESULT_PATH);
export var SEND_FEEDBACK_PATH = '/feedback';
export var GET_LOCATIONS_PATH = '/locations';
export var GET_STYLES_PATH = '/styles/:resultId';
export var CREATE_BOOKING_PATH = '/bookings/:journeyId';
export var GET_CONTENT_PATH = '/content/context/';
export var GET_CONTEXT_COMPONENT_PATH = '/content/contexts/:journeyId/:contextIds';
export var GET_CONTEXT_COMPONENT_PATH_DEV = "".concat(DEV_ROOT).concat(GET_CONTEXT_COMPONENT_PATH);
export var GET_VENDORLOCATION_PATH = '/timekitProjects/:projectId';
export var GET_AVAILABILITY_PATH = '/timekitAvailability/';
export var GET_RESCHEDULING_AVAILABILITY_PATH = '/serviceRequests/:serviceRequestToken/reschedule-availability';
export var GET_RESULT_CALENDAR_LINKS_PATH = '/results/:hash/calendar';
export var FETCH_GALLERY_IMAGES = '/images/';
export var FETCH_PRODUCTS = '/products';
export var UPLOAD_IMAGES_PATH = '/upload';
export var GET_LOCATIONS_META_PATH = '/locations/:locationId';
// region Like
export var FETCH_RESULT_LIKES = '/results/:resultId/likes';
export var LIKE_PRODUCT = '/product/:itemId/like';
export var LIKE_IMAGE = '/image/:itemId/like';
export var UNLIKE_IMAGE = '/image/:itemId/unlike';
export var UNLIKE_PRODUCT = '/product/:itemId/unlike';
// endregion
