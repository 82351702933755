var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import get from 'lodash/get';
// @ts-ignore
import * as sizes from 'conversional-theme/sizes';
import { setStyleForRoot } from '../dom/utils';
var boxes = sizes.boxes;
export var applyDynamicStyling = function (journeyConfig) {
    var loader = get(journeyConfig, 'callToAction.loader');
    if (loader) {
        var styles = loader.styles;
        injectDynamicStyles(styles);
    }
};
var injectDynamicStyles = function (styles) {
    if (!styles) {
        return;
    }
    var width = window.innerWidth;
    var currentStyle = styles.general || {};
    function parseBoxSize(size) {
        return parseInt(boxes[size].min);
    }
    if (width > parseBoxSize('lg')) {
        currentStyle = __assign(__assign({}, currentStyle), (styles.desktop || {}));
    }
    else if (width > parseBoxSize('md')) {
        currentStyle = __assign(__assign({}, currentStyle), (styles.tablet || {}));
    }
    else {
        currentStyle = __assign(__assign({}, currentStyle), (styles.mobile || {}));
    }
    for (var _i = 0, _a = Object.keys(currentStyle); _i < _a.length; _i++) {
        var styleKey = _a[_i];
        var styleValue = currentStyle[styleKey];
        setStyleForRoot(styleKey, styleValue);
    }
};
