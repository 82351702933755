var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import get from "lodash/get";
import set from "lodash/set";
import forOwn from "lodash/forOwn";
import replace from "lodash/replace";
import isObject from "lodash/isObject";
import includes from "lodash/includes";
import omit from "lodash/omit";
import merge from "lodash/merge";
// eslint-disable-next-line no-useless-escape
export var TEMPLATE_REGEX = /\<\$\$(.*?)\$\$\>/;
// eslint-disable-next-line no-useless-escape
export var TEMPLATE_REGEX_G = /\<\$\$(.*?)\$\$\>/g;
var FILTER_OPERATOR = "|";
var FILTERS = {
    JOIN: "join",
    LOWERCASE: "lowercase",
    NULLABLE: "nullable"
};
// region Filters
var FILTER_REGEX = /([a-zA-Z_$][a-zA-Z0-9_$]*)\((.*?)\)/;
function hasFilter(key) {
    return key.includes(FILTER_OPERATOR);
}
function processEachFilter(functionName, args, key, value) {
    switch (functionName) {
        case FILTERS.JOIN: {
            return value.join(args[0]);
        }
        case FILTERS.LOWERCASE: {
            return value.toLowerCase();
        }
        case FILTERS.NULLABLE: {
            return value;
        }
    }
    return value;
}
function processFilter(source, key) {
    try {
        var _a = key.split(FILTER_OPERATOR).map(function (filter) { return filter.trim(); }), dataKey = _a[0], filters = _a.slice(1);
        var value = get(source, dataKey);
        for (var _i = 0, filters_1 = filters; _i < filters_1.length; _i++) {
            var filter = filters_1[_i];
            if (!filter.includes("(")) {
                value = processEachFilter(filter, [], key, value);
            }
            else {
                var filterMatch = filter.match(FILTER_REGEX);
                var functionName = filterMatch[1];
                var filterArguments = filterMatch[2];
                var args = JSON.parse("[".concat(filterArguments.replaceAll("'", '"'), "]"));
                value = processEachFilter(functionName, args, key, value);
            }
        }
        return { value: value, filters: filters };
    }
    catch (e) {
        return { value: "", filters: [] };
    }
}
// endregion
export var containsDefinition = function (stringData) { return stringData.match(TEMPLATE_REGEX); };
function dotReplacer(obj, replaceDots) {
    if (replaceDots === void 0) { replaceDots = true; }
    // https://www.fileformat.info/info/unicode/char/0085/index.htm Used this special character since will never be used in a real config.
    var SPECIAL_CHAR = '…';
    var toFind = replaceDots ? "." : SPECIAL_CHAR;
    var toReplace = !replaceDots ? "." : SPECIAL_CHAR;
    var regex = replaceDots ? /\./g : /…/g;
    forOwn(obj, function (value, key) {
        // if key has a period, replace all occurrences with a special character
        if (includes(key, toFind)) {
            var cleanKey = replace(key, regex, toReplace);
            obj[cleanKey] = value;
            delete obj[key];
        }
        // continue recursively looping through if we have an object or array
        if (isObject(value)) {
            return dotReplacer(value, replaceDots);
        }
    });
    return obj;
}
export var unFlatten = function (data) {
    "use strict";
    if (Object(data) !== data || Array.isArray(data))
        return data;
    // eslint-disable-next-line no-useless-escape
    var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g, resultHolder = {};
    for (var p in data) {
        var cur = resultHolder, prop = "", m = void 0;
        do {
            m = regex.exec(p);
            if (!m)
                break;
            cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
            prop = m[2] || m[1];
        } while (m);
        cur[prop] = data[p];
    }
    return dotReplacer(resultHolder[""] || resultHolder, false);
};
export var flatten = function (data, _a) {
    var _b = _a.valueRegex, valueRegex = _b === void 0 ? null : _b;
    var result = {}, processedResult = {};
    data = dotReplacer(data);
    function recurse(cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
            if (valueRegex && typeof cur === "string" && cur.match(valueRegex))
                processedResult[prop] = cur;
        }
        else if (Array.isArray(cur)) {
            var l = cur.length;
            for (var i = 0; i < l; i++)
                recurse(cur[i], prop + "[" + i + "]");
            if (l == 0)
                result[prop] = [];
        }
        else {
            var isEmpty = true;
            for (var p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop + "." + p : p);
            }
            if (isEmpty && prop)
                result[prop] = {};
        }
    }
    recurse(data, "");
    return { result: result, processedResult: processedResult };
};
var PRE_RESOLVE_KEYS = ["definitions", "locale.translations"];
export var resolveDefinitions = function (raw, definitionSourceRaw, options) {
    if (options === void 0) { options = {}; }
    // Copy objects to avoid mutations
    var data = JSON.parse(JSON.stringify(raw));
    var stringData = JSON.stringify(data);
    // Do not process if we do not found a single match
    if (!containsDefinition(stringData))
        return data;
    var definitionSource = JSON.parse(JSON.stringify(definitionSourceRaw ? merge({}, data, definitionSourceRaw) : data));
    // region Pre resolve: translation, definitions
    if (!get(options, 'isPreResolve'))
        for (var _i = 0, PRE_RESOLVE_KEYS_1 = PRE_RESOLVE_KEYS; _i < PRE_RESOLVE_KEYS_1.length; _i++) {
            var preResolveKey = PRE_RESOLVE_KEYS_1[_i];
            var preResolveObject = get(definitionSource, preResolveKey);
            if (preResolveObject) {
                var definitionSourceForTranslations = omit(JSON.parse(JSON.stringify(definitionSource)), preResolveKey);
                set(definitionSource, preResolveKey, resolveDefinitions(preResolveObject, definitionSourceForTranslations, { isPreResolve: true }));
            }
        }
    // endregion
    var _a = flatten(data, {
        valueRegex: TEMPLATE_REGEX
    }), result = _a.result, processedResult = _a.processedResult;
    for (var key in processedResult) {
        var wrappedKey = processedResult[key];
        var match = wrappedKey.match(TEMPLATE_REGEX);
        var dictionaryKey = match[1];
        var value = get(definitionSource, dictionaryKey);
        var isNullable = false;
        if (hasFilter(dictionaryKey)) {
            var processedFilters = processFilter(definitionSource, dictionaryKey);
            value = processedFilters.value;
            isNullable = processedFilters.filters.includes(FILTERS.NULLABLE);
        }
        try {
            var parsed = JSON.parse(value);
            if (parsed)
                value = parsed;
        }
        catch (e) {
            //
        }
        if (wrappedKey.length - 6 !== dictionaryKey.length) {
            var finalString = result[key];
            while ((match = TEMPLATE_REGEX_G.exec(result[key]))) {
                var value_1 = get(definitionSource, match[1]);
                var isNullable_1 = false;
                if (hasFilter(match[1])) {
                    var processedFilters = processFilter(definitionSource, match[1]);
                    value_1 = processedFilters.value;
                    isNullable_1 = processedFilters.filters.includes(FILTERS.NULLABLE);
                }
                if (value_1 === undefined && !isNullable_1)
                    finalString = finalString.replace(match[0], "");
                if (typeof value_1 !== "string" && typeof value_1 !== "number")
                    continue;
                finalString = finalString.replace(match[0], value_1);
            }
            set(result, key, finalString);
        }
        else {
            // Replace whole object
            if (value || !isNullable)
                set(result, key, value);
        }
    }
    return unFlatten(result);
};
export var mergeSets = function (setA, setB) {
    return new Set(__spreadArray(__spreadArray([], Array.from(setA || []), true), Array.from(setB || []), true));
};
