var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export function create(EVENT, overrideProps) {
    if (overrideProps === void 0) { overrideProps = {}; }
    var label = overrideProps.label;
    label = EVENT.label === DYNAMIC && label ? label : EVENT.label;
    return __assign(__assign({}, EVENT), { label: label });
}
// Dynamic values are supposed to be replaced by the event payload
var DYNAMIC = 'dynamic';
export var LIKE = {
    category: "Element",
    action: "liked",
    label: DYNAMIC
};
export var DISLIKE = {
    category: "Element",
    action: "unliked",
    label: DYNAMIC
};
export var APPOINTMENT_TYPE_SELECTED = {
    category: 'Appointment.Type',
    action: 'selected',
    label: DYNAMIC
};
export var EMAIL_CAMPAIGN_CLICKED = {
    category: 'EmailCampaign',
    action: 'clicked',
    label: DYNAMIC,
};
export var EMAIL_PROVIDER_EVENT = {
    category: 'EmailProvider',
    action: 'clicked',
    label: DYNAMIC,
};
export var DOUBLEOPTIN = {
    category: 'DoubleOptIn',
    action: 'clicked',
    label: DYNAMIC,
};
// CALL TO ACTIONS
export var EXITINTENTMOBILE_CLOSE = {
    category: 'ExitIntentMobile',
    action: 'clicked',
    label: 'close',
};
export var EXITINTENTMOBILE_OPEN = {
    category: 'ExitIntentMobile',
    action: 'clicked',
    label: 'open',
};
export var EXITINTENTMOBILE_SHOW = {
    category: 'ExitIntentMobile',
    action: 'shown',
    label: '(not set)',
};
export var EXITINTENT_OPEN = {
    category: 'ExitIntent',
    action: 'clicked',
    label: 'undefined',
};
export var EXITINTENT_CLOSE = {
    category: 'ExitIntent',
    action: 'clicked',
    label: 'close',
};
export var EXITINTENT_SHOW = {
    category: 'ExitIntent',
    action: 'shown',
    label: 'undefined',
};
export var LAUNCHER_CLICK = {
    category: 'Launcher',
    action: 'clicked',
    label: 'undefined',
};
export var LAUNCHER_CLOSE = {
    category: 'Launcher',
    action: 'closed',
    label: 'undefined',
};
export var LAUNCHER_OPEN = {
    category: 'Launcher',
    action: 'opened',
    label: 'undefined',
};
export var CONVERTINGCTA = {
    category: 'ConvertingCallToAction',
    action: DYNAMIC,
    label: DYNAMIC,
};
export var PROCESS_STARTED = {
    category: 'Process',
    action: 'started',
    label: DYNAMIC,
};
export var PROCESS_PROGRESSED = {
    category: 'Process',
    action: 'progressed',
    label: DYNAMIC,
};
export var PROCESS_LAST_STEP = {
    category: 'Process',
    action: 'final_step_reached',
    label: DYNAMIC,
};
export var PROCESS_COMPLETED = {
    category: 'Process',
    action: 'completed',
    label: DYNAMIC,
};
export var PROCESS_PATH_STARTED = {
    category: 'Process.path',
    action: 'started',
    label: DYNAMIC,
};
export var PROCESS_PROGRESSED_DETAILS = {
    category: 'Process.path',
    action: 'viewedStep',
    label: DYNAMIC,
};
export var BREADCRUMB_EXPAND = {
    category: 'Breadcrumb',
    action: 'expand',
    label: 'undefined',
};
export var BREADCRUMB_CLICK = {
    category: 'Breadcrumb',
    action: 'goTo',
    label: DYNAMIC,
};
/**
 * Tracking is currently limited to 3 attributes: Category, Action and Label
 * Because of this, complex, multi-value events need to be collapsed into a single value and then expanded in tracking
 * In case of ProcessProgress the following pattern is used to contain all relevant information.
 * @param step: number - Current step count
 * @param max: number - Maximum number of steps (required to calculate completion)
 * @param attribute: string - Name of the step, e.g. "DateAndTime", "Color" or "Budget"
 * @param group: string - optional name of the type of attribute, e.g. "ContactData" or "AppointmentData"
 * @param path: string - path of the questionnaire that is currently active
 * @returns
 */
export function generateProcessProgressedEvent(step, max, attribute, group, path) {
    if (group === void 0) { group = 'undefined'; }
    if (path === void 0) { path = 'Main'; }
    return __assign(__assign({}, PROCESS_PROGRESSED_DETAILS), { label: "path:".concat(path, ";step:").concat(step, ";max:").concat(max, ";group:").concat(group, ";attribute:").concat(attribute) });
}
export var PROCESS_PATH_PROGRESSED = {
    category: 'Process.path',
    action: 'progressed',
    label: DYNAMIC,
};
export var PROCESS_PATH_COMPLETED = {
    category: 'Process.path',
    action: 'completed',
    label: DYNAMIC,
};
// CLOSURE FORM - OUTDATed
export var CLOSUREFORM_SUBMITTED = {
    category: 'closureForm',
    action: 'submitted',
    label: DYNAMIC,
};
export var PLACESEARCH_SUBMITTED = {
    category: 'PlaceSearch',
    action: 'submitted',
    label: DYNAMIC,
};
export var PLACESEARCH_SHOWN = {
    category: 'PlaceSearch',
    action: 'shown',
    label: DYNAMIC,
};
export var ANSWER_CLICKED = {
    category: 'Question',
    action: 'answer_clicked',
    label: 'undefined',
};
export var SLIDER_SUBMITTED = {
    category: 'Question',
    action: 'slider_submitted',
    label: 'undefined',
};
export var SLIDER_SETVALUE = {
    category: 'Question',
    action: 'slider_setvalue',
    label: 'undefined',
};
export var MOODBOARD_SUBMITTED = {
    category: 'Moodboard',
    action: 'submitted',
    label: DYNAMIC,
};
// Journey
export var JOURNEY_CLOSE = {
    category: 'Journey',
    action: 'click',
    label: 'close',
};
export var JOURNEY_RESTART = {
    category: 'Journey',
    action: 'click',
    label: 'restart',
};
export var JOURNEY_ENTRY = {
    category: 'Journey',
    action: 'entry',
    label: 'shown',
};
export var JOURNEY_ENTRY_HIDE = {
    category: 'Journey',
    action: 'entry',
    label: 'hidden',
};
export var JOURNEY_RESULT = {
    category: 'Journey',
    action: 'result',
    label: 'shown',
};
export var JOURNEY_RESULT_HIDE = {
    category: 'Journey',
    action: 'result',
    label: 'hidden',
};
// COMPONENTS
export var APPOINTMENTMAKER_CONFIRMATION = {
    category: 'Appointment-confirmation',
    action: 'viewed',
    label: DYNAMIC,
};
export var APPOINTMENTMAKER_SUBMIT = {
    category: 'Appointment',
    action: 'submitted',
    label: DYNAMIC,
};
export var APPOINTMENTMAKER_LOCATION = {
    category: 'Appointment-location',
    action: 'selected',
    label: DYNAMIC,
};
export var CALLTOACTION_CLICK = {
    category: 'CallToAction',
    action: 'clicked',
    label: DYNAMIC,
};
export var CALLTOACTION_SHOWN = {
    category: 'CallToAction',
    action: 'shown',
    label: DYNAMIC,
};
export var INITIAL_PAGE_VISIT = {
    category: 'page',
    action: 'visited',
    label: DYNAMIC,
};
export var COLLAPSIBLE_TOGGLED = {
    category: 'Collapsible',
    action: 'toggled',
    label: DYNAMIC,
};
export var FORM_SUBMIT = {
    category: 'Form',
    action: 'submitted',
    label: DYNAMIC,
};
export var INTERACTIVEGRID_CLOSE = {
    category: 'InteractiveGrid',
    action: 'close component',
    label: DYNAMIC,
};
export var INTERACTIVEGRID_OPEN = {
    category: 'InteractiveGrid',
    action: 'open component',
    label: DYNAMIC,
};
export var INTERACTIVEGRID_ACTIVE = {
    category: 'InteractiveGrid',
    action: 'action: closed component',
    label: DYNAMIC,
};
export var LINK_CLICK = {
    category: 'Link',
    action: 'click',
    label: DYNAMIC,
};
export var MODALBUTTON_OPEN = {
    category: 'modal',
    action: 'opened',
    label: DYNAMIC,
};
export var MODALBUTTON_CLOSE = {
    category: 'Modal',
    action: 'closed',
    label: DYNAMIC,
};
export var SECTION_VIEW = {
    category: DYNAMIC,
    action: 'viewed',
    label: DYNAMIC,
};
export var TAGGALLERY_LEFT = {
    category: 'tagGallery',
    action: 'click',
    label: 'left',
};
export var TAGGALLERY_RIGHT = {
    category: 'TagGallery',
    action: 'click',
    label: 'right',
};
export var FEEDBACK_SHOW = {
    category: 'Feedback',
    action: 'show',
    label: 'undefined',
};
export var FEEDBACK_CLOSE = {
    category: 'Feedback',
    action: 'close',
    label: 'undefined',
};
export var FEEDBACK_RATING_SUBMITTED = {
    category: 'Feedback',
    action: 'rating_submitted',
    label: DYNAMIC,
};
export var FEEDBACK_COMMENT_SUBMITTED = {
    category: 'Feedback',
    action: 'comment_submitted',
    label: DYNAMIC,
};
export var BUTTON_CLICK = {
    category: 'Button',
    action: 'click',
    label: DYNAMIC,
};
export var GENERIC_CLICK = {
    category: 'Element',
    action: 'clicked',
    label: DYNAMIC,
};
export var SCROLL_TO = {
    category: 'AnchorLink',
    action: 'clicked',
    label: DYNAMIC,
};
export var SUBMIT_TEXTAREA = {
    category: 'TextArea',
    action: 'submitted',
    label: DYNAMIC,
};
export var CAROUSEL_SWIPE = {
    category: 'Carousel',
    action: 'swiped',
    label: DYNAMIC,
};
export var CAROUSEL_FULLSCREEN = {
    category: 'Carousel',
    action: 'fullscreen',
    label: DYNAMIC,
};
export var SCROLLABLETABS_CLICKED = {
    category: 'ScrollableTabs',
    action: 'clicked',
    label: DYNAMIC,
};
export var PROFILETOGGLEBUTTON_CLICKED = {
    category: 'ProfileToggleButton',
    action: 'clicked',
    label: DYNAMIC,
};
export var TRUSTPULSE_SHOW = {
    category: 'TrustPulse',
    action: 'shown',
    label: DYNAMIC,
};
export var TRUSTPULSE_HIDE = {
    category: 'TrustPulse',
    action: 'hidden',
    label: DYNAMIC,
};
export var TRUSTPULSE_CLICKED = {
    category: 'TrustPulse',
    action: 'clicked',
    label: DYNAMIC,
};
export var TRUSTPULSE_COMPLETED = {
    category: 'TrustPulse',
    action: 'completed',
    label: DYNAMIC,
};
export var DYNAMIC_CONTENT_SHOWN = {
    category: 'DynamicContent',
    action: 'shown',
    label: DYNAMIC,
};
export var DYNAMIC_CONTENT_HIDDEN = {
    category: 'DynamicContent',
    action: 'hidden',
    label: DYNAMIC,
};
export var CHATBOT_STARTED = {
    category: 'Chatbot',
    action: 'started',
    label: DYNAMIC,
};
export var CHATBOT_CLOSED = {
    category: 'Chatbot',
    action: 'closed',
    label: DYNAMIC,
};
export var APPOINTMENT_CREATED = {
    category: 'Appointment',
    action: 'created',
    label: DYNAMIC
};
