/**
 * Create a simple JSON-RPC request object
 */
export var createRequest = function (id, messageType, payload) {
    if (payload === void 0) { payload = []; }
    return ({
        id: id,
        messageType: messageType,
        payload: payload,
    });
};
