import { appendChild, appendToRoot, createElement, getRootLauncher } from './utils';
import { getItem } from 'conversional-persistent-storage';
import { addCloseIcon, addLoadingIndicator, LAUNCHER_HIDE_KEY } from './general';
import { iconLauncherStyles } from '../styles/iconLauncher.styles';
import get from 'lodash/get';
var theme = null;
export var renderIconLauncher = function (loader, _theme) {
    theme = _theme;
    appendToRoot(createIconContainer(loader, theme));
    if (loader.labelPreview) {
        appendToRoot(createTitle(loader));
    }
};
export function createIconContainer(loader, theme) {
    var launcherDiv = createElement({
        tagName: 'div',
        uniqueId: 'launcher-icon-container',
        styles: [
            iconLauncherStyles(theme).launcherIconContainer,
            {
                backgroundColor: loader.backgroundColor,
            },
        ],
    });
    if (getItem(LAUNCHER_HIDE_KEY))
        getRootLauncher().style.display = 'none';
    var imageUrl = loader.imageUrl, closable = loader.closable, reloadOnClose = loader.reloadOnClose;
    imageUrl ? addBackgroundImage(launcherDiv, loader, theme) : addLauncherIcon(launcherDiv, loader);
    closable && addCloseIcon(launcherDiv, { reloadOnClose: reloadOnClose });
    addLoadingIndicator(launcherDiv);
    return launcherDiv;
}
export function createTitle(loader) {
    return createElement({
        tagName: 'div',
        uniqueId: 'launcher-title',
        innerHTML: loader.labelPreview,
        styles: [
            iconLauncherStyles(theme).launcherTitle,
            {
                backgroundColor: loader.backgroundColor,
            },
        ],
    });
}
export function addBackgroundImage(container, loader, theme) {
    container.style.background = "url(".concat(loader.imageUrl, ")");
    container.style.backgroundRepeat = 'no-repeat';
    container.style.backgroundSize = 'contain';
    container.style.borderStyle = 'solid';
    container.style.borderColor = loader.imageBorderColor
        ? loader.imageBorderColor
        : get(theme, 'colors.brand');
    container.style.borderWidth = loader.imageBorderWidth ? loader.imageBorderWidth : '5px';
}
export function addLauncherIcon(container, loader) {
    var icon = createElement({
        uniqueId: 'launcher-icon',
        iconKey: loader.icon || 'finger',
        attributes: {
            role: 'launcher-icon',
        },
        styles: [
            iconLauncherStyles(theme).launcherIcon,
            {
                backgroundColor: loader.backgroundColor,
            },
        ],
    });
    appendChild(container, icon);
}
