var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { StyleSheet } from 'aphrodite/no-important';
import { text, areas } from '../classes/classes';
var globalSelectorHandler = function (selector, _, generateSubtreeStyles) {
    if (selector[0] !== '*') {
        return null;
    }
    return generateSubtreeStyles(selector.slice(1));
};
var globalExtension = { selectorHandler: globalSelectorHandler };
var extended = StyleSheet.extend([globalExtension]);
var globalStyles = function (theme) {
    return extended.StyleSheet.create({
        globals: {
            '*body': {
                fontFamily: theme.typo.fonts.regular,
            },
            // Headlines
            '*h1,h2,h3,h4,h5,p,span': {
                margin: 0,
            },
            '*h1': __assign({}, text(theme).h1),
            '*h2': __assign({}, text(theme).h2),
            '*h3': __assign({}, text(theme).h3),
            '*h5': __assign({}, text(theme).h5),
            '*h6': __assign({}, text(theme).h6),
            // Images
            '*img': {
                maxWidth: '100%',
                height: 'auto'
            },
            '*a': __assign({}, text(theme).link),
            // Section
            '*section': __assign({}, areas(theme).section)
        }
    });
};
function insertGlobalStyles(theme) {
    extended.css(globalStyles(theme).globals);
}
export default insertGlobalStyles;
