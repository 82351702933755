export var breakpoints = {
    xs: {
        min: 0,
        max: 600
    },
    sm: {
        min: 601,
        max: 900
    },
    md: {
        min: 901,
        max: 1200
    },
    lg: {
        min: 1200,
        max: 1536
    },
    xl: {
        min: 1536,
        max: 9999
    }
};
