import get from 'lodash/get';
import replace from "lodash/replace";
import { urlHelpers } from 'conversional-api';
import { applyTemplate } from './transformation';
var ABTEST_KEY = 'abTest';
var INIT_PATH = 'conversionalLauncher.journeyConfig';
var _getJourneyConfigAbTest = function () { return get(window, "".concat(INIT_PATH, ".").concat(ABTEST_KEY), null); };
var _getQueryStringAbTest = function () { return get(urlHelpers.parseQueryString(), ABTEST_KEY); };
/**
 * Fetches the Current ABTest Key
 * Priority is ?abTest=XXXX Query Params
 * Otherwise abTest comes from journey config
 *
 * @return abTest : ABTest Key
 */
export var getAbTest = function () { return _getQueryStringAbTest() || _getJourneyConfigAbTest(); };
export var evaluateCustomComponentProps = function (config, props) {
    if (!config || !props || typeof config !== "object" || typeof props !== "object" || (!get(config, 'Content') && !get(config, 'Children')))
        return { Content: {}, Children: {} };
    config = applyTemplate(props, config);
    var resultStringConfig = JSON.stringify(config);
    Object.keys(props).forEach(function (key) {
        resultStringConfig = replace(resultStringConfig, new RegExp("%".concat(key, "%"), 'g'), props[key]);
    });
    try {
        return JSON.parse(resultStringConfig);
    }
    catch (e) {
        return {};
    }
};
