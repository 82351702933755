var WATCHER_INTERVAL_DURATION = 100;
var removeWatcher = {};
function watchForElementRemoval(data) {
    // Watch for element removal => used for Single Page Application client side route change
    removeWatcher[data.id] = setInterval(function () {
        if (!document.getElementById(data.targetId)) {
            // Element is removed => destroy
            window.conversionalLauncher.destroy();
            clearInterval(removeWatcher[data.id]);
        }
    }, WATCHER_INTERVAL_DURATION);
}
export function addIframeToDOM(data) {
    var iframe = window.document.createElement('iframe');
    iframe.id = data.id;
    iframe.src = data.src;
    if (data.className)
        iframe.className = data.className;
    if (data.style)
        iframe.setAttribute('style', data.style);
    var target = window.document.body;
    if (data.targetId) {
        var targetElement = document.getElementById(data.targetId);
        if (targetElement)
            watchForElementRemoval(data);
        target = targetElement || window.document.body;
    }
    target.prepend(iframe);
    return iframe;
}
