import { getJourneySettings } from 'conversional-environment';
export default function getPath() {
    // TODO for later: To get rid of passing values through path parameters, replace with with proper iframe messaging
    // This requires to handle basic config differently within the iframes because they won't be available from the start
    var path = "/?origin=".concat(encodeURIComponent(window.location), "&");
    var queryParams = [];
    var settings = getJourneySettings();
    settings['api'] = encodeURIComponent(settings['api'] || '');
    for (var param in settings) {
        if (settings[param]) {
            queryParams.push("".concat(param, "=").concat(settings[param]));
        }
    }
    if (queryParams.length) {
        path += queryParams.join('&');
    }
    return path;
}
