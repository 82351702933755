import { setItem } from 'conversional-persistent-storage';
import { appendChild, createElement, getRootLauncher } from './utils';
import { generalStyles } from '../styles/general.styles';
import { onLauncherClose } from "../actions/actions";
export var LAUNCHER_HIDE_KEY = 'conversionalLauncherHidden';
export function addCloseIcon(launcherDiv, _a) {
    var reloadOnClose = _a.reloadOnClose, styles = _a.styles;
    var closeIcon = createElement({
        tagName: 'div',
        uniqueId: 'launcher-close',
        styles: [generalStyles().launcherClose, styles],
        innerHTML: 'x',
    });
    closeIcon.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        getRootLauncher().style.display = 'none';
        setItem(LAUNCHER_HIDE_KEY, '1');
        onLauncherClose();
        // Added this timeout to make sure the tracker is invoked
        setTimeout(function () {
            reloadOnClose && window.location.reload();
        }, 200);
    });
    appendChild(launcherDiv, closeIcon);
}
export function addLoadingIndicator(launcherContainer, options) {
    if (options === void 0) { options = { className: '', styles: {} }; }
    var className = options.className, styles = options.styles;
    var loadingIndicator = createElement({
        tagName: 'span',
        uniqueId: 'launcher-loading-indicator',
        className: "".concat(className),
        attributes: {
            role: 'loading-indicator',
        },
        styles: [generalStyles().loadingIndicator, styles],
    });
    appendChild(launcherContainer, loadingIndicator);
}
