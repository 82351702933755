var THRESHOLD = 45 * 1000; // 45 seconds
export default function (callback) {
    if (callback === void 0) { callback = function () { }; }
    var time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    document.addEventListener('scroll', resetTimer, true);
    document.ontouchstart = resetTimer;
    function trigger() {
        clearTimeout(time);
        callback();
        removeListener();
    }
    function resetTimer() {
        clearTimeout(time);
        time = setTimeout(trigger, THRESHOLD);
    }
    var removeListener = function () {
        document.removeEventListener('mouseover', resetTimer);
        document.removeEventListener('keypress', resetTimer);
        document.removeEventListener('scroll', resetTimer);
        document.removeEventListener('touchstart', resetTimer);
    };
    return removeListener;
}
