export function first(array) {
    return array ? array[0] : undefined;
}
export function camelCase(input) {
    return input.toLowerCase().replace(/-(.)/g, function (match, group1) {
        return group1.toUpperCase();
    });
}
export function deepMerge(target, source) {
    // Iterate through all properties in the source object
    for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
            // Check if both the target and source properties are objects
            if (Object.prototype.hasOwnProperty.call(target, key) &&
                typeof target[key] === 'object' &&
                typeof source[key] === 'object') {
                // Recursive call for deep merging nested objects
                deepMerge(target[key], source[key]);
            }
            else {
                // Directly assign the source property to the target
                target[key] = source[key];
            }
        }
    }
    return target;
}
var getSchemaParts = function (schema) {
    var splittedBySlash = schema.split('/').filter(function (part) { return part.length > 0; });
    return splittedBySlash.map(function (part) {
        if (part.startsWith(':')) {
            return {
                type: 'param',
                value: part.split(':')[1],
            };
        }
        return {
            type: 'const',
            value: part,
        };
    });
};
export var URLParamBuilder = function (schema, options) {
    var _a = options.params, params = _a === void 0 ? {} : _a, _b = options.queryString, queryString = _b === void 0 ? {} : _b;
    var root = options.root;
    if (!root) {
        return null;
    }
    if (root[root.length - 1] === '/') {
        root = root.substring(0, root.length - 1);
    }
    var schemaParts = getSchemaParts(schema);
    var url = root;
    for (var _i = 0, schemaParts_1 = schemaParts; _i < schemaParts_1.length; _i++) {
        var part = schemaParts_1[_i];
        if (part.type)
            if (part.type === 'param') {
                if (part.value && params[part.value])
                    url += "/".concat(params[part.value]);
            }
            else {
                if (part.value)
                    url += "/".concat(part.value);
            }
    }
    url += '/';
    var queryStringKeys = Object.keys(queryString);
    for (var index = 0; index < queryStringKeys.length; index++) {
        var key = queryStringKeys[index];
        url += index === 0 ? '?' : '&';
        url += "".concat(key, "=").concat(queryString[key]);
    }
    return url;
};
