var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
// @ts-ignore
import * as domUtils from "conversional-utils/dom";
// @ts-ignore
import * as stringUtils from "conversional-utils/string";
import storage, { getItem, isStorageEnabled, setItem } from 'conversional-persistent-storage';
import { EventProcessor } from './eventProcessor';
import EventModifiers from "./eventModifiers";
var PREFIX = 'conversional';
var GTM = 'gtm';
var GTAG = 'gtag';
var GA = 'ga';
var JENTIS = 'jentis';
var MATOMO = 'matomo';
var supportedTrackers = [GA, GTM, GTAG, JENTIS, MATOMO];
var COOKIE_NAME = 'convga';
var ENABLED_KEY = 'tracking.enabled';
var ENABLED_CONVERSIONAL_KEY = 'tracking.conversionalTracking';
var ENABLED_KEY_FROM_JOURNEY_CONFIG = "conversionalLauncher.journeyConfig.configuration.".concat(ENABLED_KEY);
var ENABLED_KEY_FROM_EXTERNAL_DATA = "conversionalWidget.config.".concat(ENABLED_KEY);
var ENABLED_CONVERSIONAL_TRACKING = "conversionalLauncher.journeyConfig.configuration.".concat(ENABLED_CONVERSIONAL_KEY);
var NOCOOKIES_PERSISTENT_STORAGE_KEY = "conversional.noCookies";
var UNIQUE_USER_ID_KEY = "cSessionId";
var Tracker = /** @class */ (function () {
    function Tracker(_a) {
        var _b;
        var gid = _a.gid, ga4gid = _a.ga4gid, trackerName = _a.trackerName, ga4TrackerName = _a.ga4TrackerName, conversions = _a.conversions, journeyId = _a.journeyId, events = _a.events, eventModifier = _a.eventModifier, gaLibrary = _a.gaLibrary, tool = _a.tool, _c = _a.noCookies, noCookies = _c === void 0 ? false : _c, _d = _a.abTest, abTest = _d === void 0 ? null : _d;
        var _this = this;
        this.journeyId = null;
        this.noCookies = false;
        this.initializeTracker = function () {
            _this.initializeConversionalGATracker();
        };
        this.updateConfig = function (config) {
            Object.assign(_this, config);
            config.tool && _this.setHandler(config.tool);
        };
        // Validates if the defined tracker is available
        this.validateTracker = function (tool) {
            if (!supportedTrackers.includes(tool)) {
                return false;
            }
            if (!_this.trackingObjects[tool].validate()) {
                return false;
            }
            return true;
        };
        this.trackingObjects = (_b = {},
            //Google Tag Manager
            _b[GTM] = {
                system: GTM,
                event: function (category, action, label) {
                    var ev = "".concat(PREFIX, "-").concat(category);
                    window.dataLayer.push({
                        event: ev,
                        eventCategory: ev,
                        eventAction: action,
                        eventLabel: label,
                        nameSpace: 'conversional'
                    });
                },
                validate: function () { return !!window.dataLayer; }
            },
            //Google Analytics gTag (new version)
            _b[GTAG] = {
                system: GTAG,
                event: function (category, action, label) {
                    window.gtag('event', action, {
                        event_category: "".concat(PREFIX, "-").concat(category),
                        event_label: label
                    });
                },
                validate: function () { return !!window.gtag; }
            },
            //Google Analytics
            _b[GA] = {
                system: GTM,
                event: function (category, action, label) {
                    var prefixedCategory = "".concat(PREFIX, "-").concat(category);
                    window.ga('send', 'event', prefixedCategory, action, label);
                },
                validate: function () { return !!window.ga; }
            },
            // Jentis
            // https://docs.jentis.com/docs/jentis-data-layer-fundamentals
            // track: Conversional can help Jentis users to filter the events which are related to our tool on their website.
            _b[JENTIS] = {
                system: JENTIS,
                event: function (category, action, label, meta, tracker) {
                    var DEFAULT_EVENT = {
                        "track": "Conversional",
                        group: category,
                        name: action,
                        value: label
                    };
                    var event = (tracker.eventModifier && EventModifiers[tracker.eventModifier])
                        ? EventModifiers[tracker.eventModifier](DEFAULT_EVENT, meta)
                        : DEFAULT_EVENT;
                    if (event)
                        window._jts.push(event, true);
                },
                validate: function () { return !!window._jts; }
            },
            // Matomo
            // https://developer.matomo.org/guides/tagmanager/datalayer
            _b[MATOMO] = {
                system: MATOMO,
                event: function (category, action, label) {
                    var ev = "".concat(PREFIX, "-").concat(category);
                    window._mtm.push({
                        event: ev,
                        action: action,
                        label: label,
                        nameSpace: 'conversional'
                    });
                },
                validate: function () { return !!window._mtm; }
            },
            _b);
        this.setHandler = function (tool) {
            if (!tool) {
                return;
            }
            if (_this.validateTracker(_this.tool)) {
                _this.handler = _this.trackingObjects[_this.tool];
            }
        };
        this.event = function (_a) {
            var category = _a.category, action = _a.action, _b = _a.label, label = _b === void 0 ? 'undefined' : _b, journeyId = _a.journeyId, _c = _a.abTest, abTest = _c === void 0 ? _this.abTest : _c, context = _a.context, meta = _a.meta;
            if (abTest) {
                // Remember abTest values
                _this.abTest = abTest;
            }
            var payload = _this.eventPath
                ? { location: "".concat(window.location.origin, "/conversional").concat(_this.eventPath) }
                : {};
            //Track event for internal logging using GA4
            if (get(window, ENABLED_CONVERSIONAL_TRACKING, true)) {
                var eventPayload_1 = __assign({ event_category: category, event_label: label, send_to: _this.ga4gid, journeyId: journeyId || _this.journeyId, abTest: abTest || _this.abTest || '(not set)', context: context }, payload);
                // Dependency injection for unit tests to bypass dynamic import
                if (process.env.NODE_ENV === "test" && _this.gaLibrary)
                    // @ts-ignore
                    _this.gaLibrary.gtag('event', action, eventPayload_1);
                import("react-ga4").then(function (ReactGA4) {
                    ReactGA4.default.gtag('event', action, eventPayload_1);
                });
            }
            // Track event for external logging
            // setting events === true bypasses the filtering
            if (_this.events === true || !isEmpty(_this.eventInScope({
                event: { category: category, action: action, label: label },
                scope: _this.events
            }))) {
                if (_this.handler) {
                    _this.handler.event(category, action, label, meta, _this);
                }
            }
            _this.trackAdConversion({ category: category, action: action, label: label });
        };
        this.eventInScope = function (_a) {
            var event = _a.event, _b = _a.scope, scope = _b === void 0 ? [] : _b;
            // Check if all defined entries in any scope match all event parameters
            var matches = scope.filter(function (e) { return Object.entries(event).every(function (_a) {
                var k = _a[0], v = _a[1];
                return e[k] === undefined || e[k] === v;
            }); });
            return matches;
        };
        this.trackAdConversion = function (event) { return __awaiter(_this, void 0, Promise, function () {
            var conversionInScope, eventProcessor;
            return __generator(this, function (_a) {
                if (!this.conversions || !this.conversions.length)
                    return [2 /*return*/];
                conversionInScope = this.eventInScope({ event: event, scope: this.conversions });
                if (isEmpty(conversionInScope))
                    return [2 /*return*/];
                // Trigger Tracking Event
                if (conversionInScope) {
                    eventProcessor = new EventProcessor(conversionInScope);
                    eventProcessor.processEvents();
                }
                return [2 /*return*/];
            });
        }); };
        if (Tracker.instance) {
            return Tracker.instance;
        }
        this.gid = gid;
        this.events = events;
        this.eventModifier = eventModifier;
        this.tool = tool;
        this.ga4gid = ga4gid;
        this.abTest = abTest;
        this.trackerName = trackerName;
        this.ga4TrackerName = ga4TrackerName;
        this.journeyId = journeyId;
        this.gaLibrary = gaLibrary;
        this.noCookies = this.areCookiesEnabled(noCookies);
        this.initializeTracker();
        this.setHandler(tool);
        this.conversions = conversions || [];
        Tracker.instance = this;
    }
    Tracker.prototype.initializeConversionalGATracker = function () {
        var _this = this;
        if (!get(window, ENABLED_CONVERSIONAL_TRACKING, true))
            return;
        if (!window.gtag) {
            import("react-ga4").then(function (ReactGA4) {
                ReactGA4.default.initialize([
                    {
                        trackingId: _this.ga4gid,
                        gtagOptions: {
                            send_to: _this.ga4gid,
                            custom_map: {
                                dimension1: 'journeyId',
                                dimension2: 'abTest',
                                dimension3: 'context'
                            },
                            journeyId: _this.journeyId,
                            abTest: '(not set)',
                            context: "(not set)"
                        }
                    }
                ], {
                    testMode: process.env.NODE_ENV === 'test',
                });
                ReactGA4.default.gtag('config', _this.ga4gid, {
                    user_id: _this.getUniqueUserId()
                });
            });
        }
    };
    Tracker.prototype.getUniqueUserId = function () {
        // Singleton
        if (getItem(UNIQUE_USER_ID_KEY))
            return getItem(UNIQUE_USER_ID_KEY);
        var userId = stringUtils.generateUUID();
        setItem(UNIQUE_USER_ID_KEY, userId);
        return userId;
    };
    Tracker.prototype.areCookiesEnabled = function (noCookiesConfig) {
        if (storage.getItem(NOCOOKIES_PERSISTENT_STORAGE_KEY))
            return true;
        if (noCookiesConfig === 'false') {
            return false;
        }
        return !!noCookiesConfig;
    };
    Tracker.prototype.updateCookiePreferences = function (useCookies) {
        var noCookie = !useCookies;
        if (noCookie) {
            storage.setItem(NOCOOKIES_PERSISTENT_STORAGE_KEY, "1");
            this.deleteCookies();
        }
        else {
            storage.removeItem(NOCOOKIES_PERSISTENT_STORAGE_KEY);
        }
        return this.updateConfig({
            noCookies: noCookie
        });
    };
    Tracker.prototype.deleteCookies = function () {
        domUtils.deleteCookie(COOKIE_NAME);
        domUtils.deleteCookie("".concat(COOKIE_NAME, "_gid"));
    };
    return Tracker;
}());
export { Tracker };
var NoTracker = /** @class */ (function () {
    function NoTracker() {
        this.event = function (payload) {
        };
        this.updateConfig = function (config) { return null; };
    }
    return NoTracker;
}());
var NoTrackerInstance = new NoTracker;
/**
 * TrackerProxy evaluates the current configuration and either
 * returns a dummy or instantiates/returns the actual tracking class
 */
var TrackerProxy = /** @class */ (function () {
    function TrackerProxy(trackerConfig) {
        var _this = this;
        this.getHandler = function () {
            if (!_this.isTrackingEnabled()) {
                return NoTrackerInstance;
            }
            if (!_this.trackerInstance)
                _this.trackerInstance = new Tracker(_this.trackerConfig);
            return _this.trackerInstance;
        };
        this.updateConfig = function (config) {
            if (config) {
                Object.assign(_this.trackerConfig, config);
            }
            if (_this.trackerInstance) {
                _this.trackerInstance.updateConfig(_this.trackerConfig);
            }
        };
        this.updateCookiePreferences = function (useCookies) {
            if (_this.trackerInstance) {
                _this.trackerInstance.updateCookiePreferences(useCookies);
            }
        };
        this.event = function (eventData) {
            _this.getHandler().event(eventData);
        };
        if (TrackerProxy.instance) {
            return TrackerProxy.instance;
        }
        this.trackerConfig = trackerConfig;
        TrackerProxy.instance = this;
    }
    TrackerProxy.prototype.isTrackingEnabled = function () {
        var enabledFromGDPR = get(window, ENABLED_KEY_FROM_EXTERNAL_DATA, undefined);
        if (enabledFromGDPR !== undefined) {
            return enabledFromGDPR;
        }
        var enabledFromConsent = isStorageEnabled();
        if (!enabledFromConsent)
            return false;
        return get(window, ENABLED_KEY_FROM_JOURNEY_CONFIG, true);
    };
    return TrackerProxy;
}());
export { TrackerProxy };
var trackerProxy;
export default (function (config) {
    if (!trackerProxy) {
        trackerProxy = new TrackerProxy(config);
    }
    trackerProxy.updateConfig(config);
    return trackerProxy;
});
