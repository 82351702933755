import get from "lodash/get";
import { iframeTemplates } from "conversional-iframe-communication";
import { addClassToElement, appendChild, createElement, getRootLauncher, launcherAvailable, removeClassFromElement } from './utils';
import { CONVERSIONAL_LOADING_OVERLAY_ID } from '../constants';
import loadingBars from '../constants/loadingBars';
import { generalStyles } from '../styles/general.styles';
var INLINE_LOADING_CONTAINER_ID = "conversional-inline-loading-container";
// region DOM Functions
function createLoadingOverlay(loadingOverlay) {
    var overlayContainer = createElement({
        tagName: 'div',
        id: CONVERSIONAL_LOADING_OVERLAY_ID,
        uniqueId: CONVERSIONAL_LOADING_OVERLAY_ID,
        styles: generalStyles().loadingOverlayContainer,
    });
    var body = createLoadingBody();
    var title = typeof loadingOverlay === 'object' && createLoadingTitle(loadingOverlay.title);
    var subtitle = typeof loadingOverlay === 'object' && createLoadingSubtitle(loadingOverlay.subtitle);
    var bars = createLoadingImage();
    appendChild(body, title);
    appendChild(body, subtitle);
    appendChild(body, bars);
    appendChild(overlayContainer, body);
    return overlayContainer;
}
function createLoadingBody() {
    return createElement({
        tagName: 'div',
        uniqueId: 'loading-body',
        styles: generalStyles().loadingBody,
    });
}
function createLoadingTitle(title) {
    return createElement({
        tagName: 'div',
        uniqueId: 'loading-title',
        styles: generalStyles().loadingTitle,
        innerHTML: title,
    });
}
function createLoadingSubtitle(subtitle) {
    return createElement({
        tagName: 'div',
        uniqueId: 'loading-subtitle',
        styles: generalStyles().loadingSubtitle,
        innerHTML: subtitle,
    });
}
function createLoadingImage() {
    return createElement({
        tagName: 'div',
        uniqueId: 'loading-bars',
        styles: generalStyles().loadingBars,
        innerHTML: loadingBars,
    });
}
function createInlineLoading() {
    var inlineLoadingContainer = createElement({
        tagName: 'div',
        id: INLINE_LOADING_CONTAINER_ID,
        uniqueId: 'inline-loading-container',
        styles: generalStyles().inlineLoadingContainer,
    });
    var loadingBars = createLoadingImage();
    appendChild(inlineLoadingContainer, loadingBars);
    return inlineLoadingContainer;
}
// endregion
export var renderLoading = function (loadingOverlay) {
    if (!loadingOverlay)
        return;
    var html = createLoadingOverlay(loadingOverlay);
    appendChild(document.body, html);
};
export var checkInlineWidgetAndRenderLoading = function (configuration) {
    var inlineConfiguration = get(configuration, 'entry.Window', {});
    if (inlineConfiguration.template !== iframeTemplates.INLINE)
        return;
    var target = inlineConfiguration.target;
    if (!target)
        return;
    var targetElement = document.getElementById(target);
    if (!targetElement)
        return;
    var targetElementHeight = targetElement.offsetHeight || targetElement.clientHeight;
    if (targetElementHeight < 100)
        return;
    targetElement.style.position = "relative";
    var loadingContainer = createInlineLoading();
    targetElement.append(loadingContainer);
};
export var setInlineLoading = function (loading) {
    var _a, _b;
    if (loading)
        return;
    var inlineLoadingContainer = document.getElementById(INLINE_LOADING_CONTAINER_ID);
    if (!inlineLoadingContainer)
        return;
    // @ts-ignore
    (_b = (_a = inlineLoadingContainer.parentElement) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.position = null;
    inlineLoadingContainer.remove();
};
export var getLoadingOverlayContainer = function () {
    return document.getElementById(CONVERSIONAL_LOADING_OVERLAY_ID);
};
var showLoadingOverlay = function (loadingOverlay) {
    if (loadingOverlay) {
        loadingOverlay.style.visibility = 'visible';
        // To have a smooth transition with opacity
        setTimeout(function () {
            loadingOverlay.style.opacity = 1;
        }, 10);
    }
};
var hideLoadingOverlay = function (loadingOverlay) {
    if (loadingOverlay) {
        setTimeout(function () {
            loadingOverlay.style.visibility = 'hidden';
            loadingOverlay.style.opacity = 0;
        }, 1000);
    }
};
export var setLoading = function (loading, options) {
    var timeout = options === null || options === void 0 ? void 0 : options.timeout;
    setInlineLoading(loading);
    setEarlyLoading(loading);
    if (!launcherAvailable()) {
        return;
    }
    var loadingOverlay = getLoadingOverlayContainer();
    var launcher = getRootLauncher();
    var cvLoadingClassName = 'cv-loading';
    if (loading) {
        if (loadingOverlay) {
            loadingOverlay.style.visibility = 'visible';
        }
        addClassToElement(launcher, cvLoadingClassName);
    }
    else {
        if (loadingOverlay) {
            setTimeout(function () {
                loadingOverlay.style.visibility = 'hidden';
            }, 1000);
        }
        var closeFn = function () { return removeClassFromElement(launcher, cvLoadingClassName); };
        /**
         * Moved delay functionality to the setLoading function to ensure only one agent is working on the loading.
         * */
        if (timeout) {
            window.conversionalLauncher.loadingTimeout = setTimeout(closeFn, timeout);
        }
        else {
            clearTimeout(window.conversionalLauncher.loadingTimeout);
            closeFn();
        }
    }
};
export var setEarlyLoading = function (loading) {
    var loadingOverlay = getLoadingOverlayContainer();
    if (loading) {
        showLoadingOverlay(loadingOverlay);
    }
    else {
        hideLoadingOverlay(loadingOverlay);
    }
};
