// @ts-ignore
import * as urlHelpers from "conversional-api/url-helpers";
var CLASSNAME = 'rt-stylecheck';
/**
 * Returns a list of all call to actions present in the DOM
 */
export function onCTAShown(callback) {
    var ctas = window.document.querySelectorAll(".".concat(CLASSNAME));
    for (var i = 0; i < ctas.length; i++) {
        var cta = ctas[i];
        var label = getLabelFromHTMLElement(cta);
        callback(label);
    }
}
/**
 * Attaches callback function to CTA click events
 */
export var onCTAClick = function (callback) {
    window.document.addEventListener('click', function (e) {
        CTAClickHandler(e, callback);
    }, false);
};
/**
 * Evaluates if any element has been clicked that contains a CTA
 * If so executes the callback
 */
var CTAClickHandler = function (e, callback) {
    if (!clickTargetContainsCTA(e)) {
        return;
    }
    callback(getLabelFromHTMLElement(e.target));
};
var checkClassNameForCTA = function (e) {
    try {
        var target = e.target;
        var isStyleCheck = Boolean(target && target.classList && target.classList.contains(CLASSNAME));
        var hasStyleCheckParent = Boolean(target.closest(".".concat(CLASSNAME)));
        if (!isStyleCheck && !hasStyleCheckParent) {
            return false;
        }
        return true;
    }
    catch (error) {
        //eslint-disable-next-line no-console
        return false;
    }
};
var checkURLParameterForCTA = function (e) {
    try {
        var target = e.target;
        if (!target.href)
            return false;
        var queryParams = urlHelpers.parseHref(target.href);
        /**
            If openWidget is present in the URL parameters, we'll prevent URL change and just update the route
            so that other features like particular path opening works.
         */
        if (queryParams[urlHelpers.URL_PARAM_OPEN_WIDGET]) {
            history.replaceState({}, document.title, target.href);
            e.preventDefault();
            e.stopPropagation();
            return true;
        }
        return false;
    }
    catch (error) {
        //eslint-disable-next-line no-console
        return false;
    }
};
var clickTargetContainsCTA = function (e) {
    var CTACheckers = [checkClassNameForCTA, checkURLParameterForCTA];
    return CTACheckers.some(function (CTAChecker) { return CTAChecker(e); });
};
/**
 * Extracts a limited string label as value for call to action tracking events
 * Is used to identify which custom call to action has been clicked on.
 */
export function getLabelFromHTMLElement(node) {
    var label = node.outerHTML || '';
    label = label.substring(0, 400); // Limit due to google compatibility
    return label;
}
/**
 * Adds CSS style to make call to actions appear clickable.
 */
export var createStyleTagForCTAs = function () {
    var styles = ".rt-stylecheck {cursor: pointer;}";
    var style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(styles));
    document.head.appendChild(style);
};
