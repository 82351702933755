var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import envConfig from 'conversional-environment/config';
import get from 'lodash/get';
var env = process.env.REACT_APP_JOURNEY_ENV || 'development';
export function isConversionalSentryEvent(errorEvent) {
    if (env === 'development')
        return true;
    try {
        var fileNames_1 = [];
        var exceptionValues = get(errorEvent, 'exception.values', []);
        exceptionValues.forEach(function (exceptionValue) {
            exceptionValue.stacktrace.frames.forEach(function (frame) {
                fileNames_1.push(frame.filename);
            });
        });
        return (fileNames_1.filter(function (fileName) {
            var cleanFileName = fileName;
            // Normally it should be a URL but try/catch is to ensure we do not filter important stuff
            try {
                var url = new URL(fileName);
                cleanFileName = "".concat(url.origin).concat(url.pathname);
                // eslint-disable-next-line no-empty
            }
            catch (e) { }
            return !cleanFileName.includes('sentry') && cleanFileName.includes('conversional');
        }).length > 0);
    }
    catch (e) {
        // Return true in error cases to find unknown errors to enhance the function over time
        return false;
    }
}
export function initializeBundleErrorTracker(journey) {
    var errorTracker = get(journey, 'tracking.errorTracker', 'sentry');
    switch (errorTracker) {
        case 'sentry': {
            var sentryBundleDSN_1 = get(envConfig, 'sentry.bundle');
            if (sentryBundleDSN_1) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                import('@sentry/browser').then(function (Sentry) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    var extraSentryOptions = {};
                    var hostSessionReplayEnabled = get(journey, 'tracking.hostSessionReplay', false);
                    var integrations = [];
                    if (hostSessionReplayEnabled) {
                        extraSentryOptions.replaysSessionSampleRate = 0.1;
                        extraSentryOptions.replaysOnErrorSampleRate = 1;
                        integrations.push(new Sentry.Replay({
                            maskAllText: false,
                        }));
                    }
                    Sentry.init(__assign({ dsn: sentryBundleDSN_1, beforeSend: function (event) {
                            if (event.level === 'info')
                                return null;
                            if (!isConversionalSentryEvent(event))
                                return null;
                            return event;
                        }, initialScope: {
                            tags: {
                                journeyId: get(journey, 'id'),
                                origin: window.location.origin,
                            },
                        }, 
                        // Reason: https://bitbucket.org/ConversionalDev/conversional-frontend/pull-requests/1157
                        ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'], environment: env, 
                        // debug: env === "development",
                        release: get(window, 'conversionalLauncher.release'), tracesSampleRate: 0, integrations: integrations }, extraSentryOptions));
                });
            }
        }
    }
}
