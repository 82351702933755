import get from "lodash/get";
var DEFAULT_ERROR_TRACKER = 'sentry';
var formatError = function (error) {
    if (error == null) {
        return;
    }
    if (typeof error === 'string') {
        return new Error(error);
    }
    if (typeof error !== 'object') {
        return;
    }
    if (error instanceof Error) {
        return error;
    }
    if (error.message) {
        return new Error(error.message);
    }
    // if it was an object
    var message = Object.entries(error)
        .filter(function (_a) {
        var key = _a[0];
        return key !== 'isCaptured';
    })
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        return "".concat(key, ":").concat(value === null || value === void 0 ? void 0 : value.toString());
    })
        .join('\n');
    return new Error(message);
};
var captureException = function (error, info, options) {
    if (info === void 0) { info = {}; }
    if (options === void 0) { options = { host: false }; }
    // eslint-disable-next-line no-console
    console.error(error, info);
    var errorTracker, sessionReplay;
    if ((options === null || options === void 0 ? void 0 : options.host) || !window.reduxStore) {
        errorTracker = DEFAULT_ERROR_TRACKER;
        sessionReplay = false;
    }
    else {
        var storeState = window.reduxStore.getState();
        var currentJourneyConfig = get(storeState, 'journeys.journeyId', get(storeState, 'config.journeyId'));
        if (!currentJourneyConfig)
            return;
        errorTracker = get(currentJourneyConfig, 'tracking.errorTracker', DEFAULT_ERROR_TRACKER);
        sessionReplay = get(currentJourneyConfig, 'tracking.sessionReplay', false);
    }
    switch (errorTracker) {
        case 'sentry': {
            import('@sentry/react').then(function (Sentry) {
                if (sessionReplay) {
                    var replay = Sentry.getCurrentHub().getClient().getIntegration(Sentry.Replay);
                    if (!get(replay, '_replay._isEnabled')) {
                        replay.start();
                    }
                }
                Sentry.withScope(function (scope) {
                    Object.keys(info).forEach(function (key) {
                        scope.setExtra(key, info[key]);
                    });
                    Sentry.captureException(formatError(error));
                });
            });
        }
    }
};
export default captureException;
