import isEmpty from "lodash/isEmpty";
export var randomString = function (length) {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
};
export function getPosition(string, subString, index) {
    if (index === void 0) { index = 0; }
    return string.split(subString, index).join(subString).length;
}
export var generateUUID = function () {
    var u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
    return [u.substring(0, 8), u.substring(8, 12), '4000-8' + u.substring(13, 16), u.substring(16, 28)].join('-');
};
export var getFileSize = function (size) {
    return "".concat((size / 1000000).toFixed(2), " MB");
};
export var isEmptyString = function (text) { return typeof text !== "string" ? isEmpty(text) : isEmpty((text || "").trim()); };
export function capitalizeFirstLetter(str) {
    if (str === void 0) { str = ""; }
    return str.charAt(0).toUpperCase() + str.slice(1);
}
