var isMobile = window.innerWidth < 600;
export var generalStyles = function () { return ({
    launcherRoot: {
        height: '75px',
        bottom: '15px',
        transform: 'translateY(-15px)',
        position: 'fixed',
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'center',
        zIndex: 9999,
        lineHeight: '1rem',
        transition: 'filter 200ms',
        fontFamily: 'Roboto Regular',
        ':hover': {
            filter: 'brightness(90%)',
        },
    },
    loadingOverlayContainer: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        margin: '0 auto',
        display: 'flex',
        visibility: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0,
        transition: "200ms",
        background: 'rgba(66,66,66,0.75)',
    },
    'position-left': {
        left: '15px',
    },
    'position-right': {
        right: '15px',
    },
    loadingBody: {
        width: isMobile ? '50%' : '100%',
        maxWidth: '400px',
        padding: isMobile ? '10px 10px' : '80px 40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        lineHeight: 1.2,
        flexDirection: 'column',
        background: '#fff',
        borderRadius: '5px',
        zIndex: 1000,
        boxShadow: '0px 4px 4px 0px #0000004D',
        fontFamily: 'Roboto Regular',
    },
    loadingTitle: {
        fontSize: '32px',
        fontWeight: 700,
        marginBottom: '10px',
    },
    loadingSubtitle: {
        fontSize: '22px',
        marginBottom: '10px',
    },
    loadingBars: {
        width: '70px',
        marginTop: '10px',
    },
    launcherClose: {
        position: 'absolute',
        top: '4px',
        right: '0',
        background: '#464646',
        width: '18px',
        height: '18px',
        textAlign: 'center',
        borderRadius: '100%',
        color: '#fff',
        transition: '200ms',
        ':hover': {
            transform: 'scale(1.1)',
            background: '#111',
        },
    },
    loadingIndicator: {
        display: 'none',
        border: '6px solid rgba(255, 255, 255, .4)',
        opacity: 0.8,
        borderRadius: '50%',
        borderTop: '6px solid rgba(255, 255, 255, .8)',
        width: '30px',
        height: '30px',
        '-webkit-animation': 'spin 2s linear infinite',
        animation: 'spin 2s linear infinite',
        zIndex: 100,
    },
    inlineLoadingContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: "0px",
        right: "0px"
    },
    iconContainer: {
        'z-index': 2,
    },
}); };
