import { urlHelpers } from 'conversional-api';
/**
 * Determines if the widget should open based on the URL parameters
 */
export var URL_PARAM_OPEN_WIDGET = urlHelpers.URL_PARAM_OPEN_WIDGET;
export function widgetShouldOpen(urlParams) {
    // In some cases it was not possible to get the query string parameters from window.location
    // This is probably due to the use of Vue
    // Instead the href string need s to be parsed manually
    var widgetShouldOpen = Boolean(URL_PARAM_OPEN_WIDGET in urlParams) ||
        Boolean('Onlineberatung' in urlParams) ||
        Boolean('onlineberatung' in urlParams);
    if (!widgetShouldOpen && window.location.href.indexOf(URL_PARAM_OPEN_WIDGET)) {
        urlParams[URL_PARAM_OPEN_WIDGET] = urlHelpers.parseHref(window.location.href)[URL_PARAM_OPEN_WIDGET];
    }
    return Boolean(urlParams[URL_PARAM_OPEN_WIDGET] || widgetShouldOpen);
}
/**
 * Function will read URL parameters that are needed in the queries for dynamic cnotent
 * This will extract the ?dynamic parameter as well as the nested &content=key:value,key2:value2
 */
export function extractDynamicContentParams() {
    var parameters = new URLSearchParams(window.location.search);
    if (!parameters.get('dynamic')) {
        return {};
    }
    var content = parameters.get('content');
    var contentParams = extractCommaSeparatedParams(content);
    return {
        dynamic: parameters.get('dynamic'),
        contentParams: contentParams,
    };
}
export var extractCommaSeparatedParams = function (urlPart) {
    var params = {};
    if (urlPart && typeof urlPart === 'string') {
        urlPart.split(',').map(function (pair) {
            var record = pair.split(':');
            params[record[0]] = record[1] || null;
        });
    }
    return params;
};
