var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import isEmpty from 'lodash/isEmpty';
import { urlHelpers } from 'conversional-api';
import { iframeCreators } from 'conversional-iframe-communication';
import { startTracker, trackLaunchEvents, trackUrlParams } from './actions/tracking';
import { widgetShouldOpen, } from './urlparams';
import { applyExternalData, disableCookies, getConfig, getSettings, trackingEnabled, } from './config';
import { trackCTAViews } from './actions/callToActions';
import { initializeBundleErrorTracker } from './actions/errorTracker';
import { setLoading } from "./dom/loading";
import { processUrlParams, showDynamicContent, showJourney } from "./actions/actions";
var onIframesReady = function () {
    applyExternalData();
    processUrlParams();
    trackLaunchEvents();
    trackUrlParams(urlHelpers.parseQueryString());
    // initialize the public API. It will be exposed under conversionalClient.onReady() by the lightweight application
    // this function is required by the lightweight-launcher
    window.conversionalOpenWidgetClicked && showJourney();
};
var iframesCreating = false;
var iframesCreated = false;
function createAllIframes(config, settings) {
    return new Promise(function (resolve, reject) {
        // To prevent double creation of iframes
        if (iframesCreating)
            return;
        iframesCreating = true;
        var promises = __spreadArray(__spreadArray([], iframeCreators.createAllIframes(config, settings), true), [showDynamicContent()], false);
        Promise.all(promises).then(function () {
            iframesCreated = true;
            onIframesReady();
            resolve(1);
        });
    });
}
export default function init() {
    return new Promise(function (resolve, reject) {
        var config = getConfig();
        if (!isEmpty(config)) {
            trackCTAViews();
            initializeBundleErrorTracker(config);
            startTracker(__assign(__assign({}, config), { noCookies: disableCookies(), enabled: trackingEnabled() }));
            createAllIframes(config, getSettings()).then(resolve);
            if (widgetShouldOpen(urlHelpers.parseQueryString())) {
                setLoading(true);
            }
        }
        else {
            // In flash mode, we are loading this bundle in parallel with sending the journey config request
            // So sometimes, in flash mode, we should retry the init config to see if the config is available or not
            setTimeout(function () { return init(); }, 10);
        }
    });
}
