import { MESSAGETYPES } from 'conversional-iframe-communication';
import { getTracker } from 'conversional-analytics';
var WidgetClient = /** @class */ (function () {
    function WidgetClient(request, setCallback) {
        var _this = this;
        /**
         * Clears cache on widget application - development use only
         */
        this.clearCache = function (id) {
            if (id === void 0) { id = Date.now(); }
            if (process.env.REACT_APP_JOURNEY_ENV !== 'development')
                return;
            return _this.request(id, MESSAGETYPES.RPC_CLEAR_CACHE);
        };
        /**
         * Trigger a JSON-RPC request to show Exit Intent
         */
        this.openExitIntent = function (id) {
            if (id === void 0) { id = Date.now(); }
            return _this.request(id, MESSAGETYPES.RPC_OPEN_EXIT_INTENT);
        };
        /**
         * Trigger a JSON-RPC request to disable Exit Intent
         */
        this.disableExitIntent = function (id) {
            if (id === void 0) { id = Date.now(); }
            return _this.request(id, MESSAGETYPES.RPC_DISABLE_EXIT_INTENT);
        };
        /**
         * Trigger a JSON-RPC request to enable Exit Intent
         */
        this.enableExitIntent = function (id) {
            if (id === void 0) { id = Date.now(); }
            return _this.request(id, MESSAGETYPES.RPC_ENABLE_EXIT_INTENT);
        };
        /**
         * Trigger a JSON-RPC request to show Journey
         */
        this.openJourney = function (id, payload) {
            if (id === void 0) { id = Date.now(); }
            if (payload === void 0) { payload = {}; }
            // backward compatibility
            if (typeof id === 'object') {
                payload = id;
                id = Date.now();
            }
            return _this.request(id, MESSAGETYPES.RPC_OPEN_JOURNEY, payload);
        };
        /**
         * Trigger a JSON-RPC request to hide Journey
         */
        this.closeJourney = function (id) {
            if (id === void 0) { id = Date.now(); }
            return _this.request(id, MESSAGETYPES.RPC_CLOSE_JOURNEY);
        };
        /**
         * Trigger a JSON-RPC to set external data, e.g. for use in the externalDataAdapter that transforms data into props
         */
        this.setExternalData = function (id, data) {
            if (id === void 0) { id = Date.now(); }
            return _this.request(id, MESSAGETYPES.RPC_SET_EXTERNAL, data);
        };
        /**
         * Trigger a JSON-RPC request to update Journey config
         */
        this.updateJourneyConfigurationTheme = function (id, config) {
            if (id === void 0) { id = Date.now(); }
            return _this.request(id, MESSAGETYPES.RPC_UPDATE_JOURNEY_CONFIGURATION_THEME, JSON.parse(config));
        };
        this.updateJourneyConfigurationCTA = function (id, config) {
            if (id === void 0) { id = Date.now(); }
            return _this.request(id, MESSAGETYPES.RPC_UPDATE_JOURNEY_CONFIGURATION_CTA, JSON.parse(config));
        };
        this.updateJourneyConfigurationEntry = function (id, config) {
            if (id === void 0) { id = Date.now(); }
            return _this.request(id, MESSAGETYPES.RPC_UPDATE_JOURNEY_CONFIGURATION_ENTRY, JSON.parse(config));
        };
        //
        // /**
        //  * Callback called when result opens
        //  */
        // onResultOpened = (callback: Function) => {
        //   this.setCallback(MESSAGETYPES.JOURNEY_SHOW_RESULT, callback)
        // }
        /**
         * Callback called when result is created
         */
        this.onResultCreated = function (callback) {
            _this.setCallback(MESSAGETYPES.JOURNEY_CREATE_RESULT, callback);
        };
        /**
         * Set Cookie Enabled
         */
        this.setCookieUsage = function (value) {
            getTracker().updateCookiePreferences(value);
        };
        this.request = request;
        this.setCallback = setCallback;
    }
    return WidgetClient;
}());
export { WidgetClient };
export default WidgetClient;
