var THRESHOLD = 120;
var DELAY = 400;
export default function onLeaveSite(callback) {
    if (callback === void 0) { callback = function () { }; }
    var eventExit = 'mouseleave';
    var stopExit = 'mouseenter';
    var target = window.document.documentElement;
    var _delayTimer = null;
    function handleMouseLeave(e) {
        if (e.clientY > THRESHOLD) {
            return;
        }
        _delayTimer = setTimeout(callback, DELAY);
    }
    var visibilityChange = function () {
        if (document.visibilityState !== 'visible') {
            callback();
        }
    };
    document.addEventListener('visibilitychange', visibilityChange);
    function handleMouseEnter() {
        if (_delayTimer) {
            clearTimeout(_delayTimer);
            _delayTimer = null;
        }
    }
    target.addEventListener(eventExit, handleMouseLeave);
    target.addEventListener(stopExit, handleMouseEnter);
    var removeListener = function () {
        target.removeEventListener(eventExit, handleMouseLeave);
        target.removeEventListener(stopExit, handleMouseEnter);
        document.removeEventListener('visibilitychange', visibilityChange);
        clearTimeout(_delayTimer);
    };
    return removeListener;
}
