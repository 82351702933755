var monitorMobileExit = function (callback) {
    if (callback === void 0) { callback = function () { }; }
    var previousTop = 0;
    var hasScrolledDown = false;
    var shown = false;
    var listener = function () {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        var dir = top > previousTop ? 'down' : 'up';
        if (dir === 'down' && !hasScrolledDown) {
            hasScrolledDown = true;
        }
        else if (dir === 'up' && hasScrolledDown && !shown) {
            callback();
            shown = true;
            hasScrolledDown = false;
            window.document.removeEventListener('scroll', listener);
        }
        previousTop = top;
    };
    var listenToScrollUp = function () {
        window.document.removeEventListener('touchstart', listenToScrollUp);
        window.document.addEventListener('scroll', listener);
    };
    // Attaches an onTouch event that ensures this code will only be executed on devices used as touch
    window.document.addEventListener('touchstart', listenToScrollUp);
    return function () {
        window.document.removeEventListener('touchstart', listenToScrollUp);
        window.document.removeEventListener('scroll', listener);
    };
};
export default monitorMobileExit;
