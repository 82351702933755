export var sizes = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl'
};
export var boxes = {
    xs: {
        min: '0px',
        max: '600px'
    },
    sm: {
        min: '601px',
        max: '900px'
    },
    md: {
        min: '901px',
        max: '1200px'
    },
    lg: {
        min: '1200px',
        max: '1536px'
    },
    xl: {
        min: '1536px',
        max: '9999px'
    }
};
export function getBoxBreakpoints(size, breakpoint) {
    if (breakpoint === void 0) { breakpoint = boxes; }
    var box = breakpoint[size];
    return box
        ? {
            min: parseInt(box.min) || 0,
            max: parseInt(box.max) || Infinity
        }
        : {};
}
/**
 * Creates media query string from certain breakpoint up
 * @param size
 * @returns {string}
 */
export var mediaBreakpointUp = function (size, breakpoint) {
    if (breakpoint === void 0) { breakpoint = boxes; }
    return "@media only screen and (min-width: ".concat(breakpoint[size].min, ")");
};
/**
 * Creates string from certain breakpoint down
 * @param size
 * @returns {string}
 */
export var mediaBreakpointDown = function (size, breakpoint) {
    if (breakpoint === void 0) { breakpoint = boxes; }
    var mq = breakpoint[size].max ? "@media only screen and (max-width: ".concat(breakpoint[size].max, ")") : '';
    return mq;
};
export var noPrint = {
    "@media print": {
        display: "none"
    }
};
export var mediaPrint = "@media print";
export var mediaBreakpointBetween = function (lower, upper, breakpoint) {
    if (breakpoint === void 0) { breakpoint = boxes; }
    var lowerLimit = "(min-width: ".concat(breakpoint[lower].min, ")");
    var upperLimit = breakpoint[upper] ? "and (max-width: ".concat(breakpoint[upper].max, ")") : '';
    var mq = "@media only screen and ".concat(lowerLimit, " ").concat(upperLimit);
    return mq;
};
export var mediaBreakpointOnly = function (size, breakpoint) {
    if (breakpoint === void 0) { breakpoint = boxes; }
    var lowerLimit = "(min-width: ".concat(breakpoint["".concat(size)].min, ")");
    var upperLimit = "and (max-width: ".concat(breakpoint[size].max, ")");
    var mq = "@media only screen and ".concat(lowerLimit, " ").concat(upperLimit);
    return mq;
};
export function getWindowWidth() {
    return window.parentWidth || window.innerWidth || document.documentElement.clientWidth;
}
export var widthBreakpointUp = function (size, breakpoint) {
    if (breakpoint === void 0) { breakpoint = boxes; }
    var w = getWindowWidth();
    return parseInt(breakpoint[size].max) < w;
};
export var widthBreakpointDown = function (size, breakpoint) {
    if (breakpoint === void 0) { breakpoint = boxes; }
    var w = getWindowWidth();
    return parseInt(breakpoint[size].max) > w;
};
export var isMobile = function () {
    return widthBreakpointDown("xs");
};
export var isTablet = function () {
    return widthBreakpointDown("sm");
};
export var isDesktop = function () {
    return !isMobile() && !isTablet();
};
export var multiplySize = function (value, by) {
    if (!by || by === 1)
        return value;
    if (typeof value === "number")
        return value * 2;
    var _a = value.match(/[\d.]+|\D+/g), numValue = _a[0], unit = _a[1];
    return "".concat(parseFloat(numValue) * by).concat(unit);
};
export default boxes;
