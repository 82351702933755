import merge from 'lodash/merge';
import { ComponentClass } from 'conversional-theme';
var PARENT_BODY_RESET_STYLES = "margin: initial; overflow: initial; height: initial;";
export var DEFAULT_IFRAME_STYLE = {
    general: { position: 'fixed', border: 'none', zIndex: 1, width: 0, height: 0 },
};
export var mixStyles = function (currentStyles, newStyles, replace) {
    var styles = replace ? newStyles : merge(currentStyles, newStyles);
    return {
        className: ComponentClass(styles), rawStyles: styles
    };
};
var updateOriginalStyles = function () {
    var currentBodyStyles = window.document.body.getAttribute("style");
    if (currentBodyStyles !== PARENT_BODY_RESET_STYLES)
        originalBodyStyles = currentBodyStyles;
};
var originalBodyStyles = "";
export var styleParentBody = function (_a) {
    var styles = _a.styles;
    updateOriginalStyles();
    for (var prop in styles) {
        var value = styles[prop];
        if (typeof value === 'number') {
            value = "".concat(value, "px");
        }
        window.document.body.style[prop] = value;
    }
};
export var resetParentBodyStyles = function () {
    window.document.body.setAttribute("style", originalBodyStyles || PARENT_BODY_RESET_STYLES);
};
