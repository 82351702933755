import { isMobile } from './deviceUtil';
export function isInFullScreen() {
    var document = window.document;
    return ((document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null));
}
export function requestFullScreen(elem) {
    if (elem.requestFullscreen) {
        elem.requestFullscreen();
    }
    else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
    }
    else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen();
    }
    else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
    }
    else {
        console.warn('Did not find a requestFullScreen method on this element', elem);
    }
}
export function exitFullScreen() {
    var document = window.document;
    if (document.exitFullscreen) {
        document.exitFullscreen();
    }
    else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    }
    else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    }
    else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    }
}
export function toggleFullScreen(_a) {
    var _b = _a.elem, elem = _b === void 0 ? document.body : _b, justMobile = _a.justMobile;
    // based on https://stackoverflow.com/questions/36672561/how-to-exit-fullscreen-onclick-using-javascript
    if (justMobile && !isMobile())
        return;
    if (isInFullScreen()) {
        exitFullScreen();
    }
    else {
        requestFullScreen(elem);
    }
}
