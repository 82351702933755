var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import get from 'lodash/get';
// @ts-ignore
import * as domUtils from "conversional-utils/dom";
import { colors } from './colors/colors';
import { borders } from './borders/borders';
import { elements } from './elements/elements';
import { behavior, defaultText, text } from './classes/classes';
import typography from './typography/typography';
import spaces from './spaces/spaces';
import { breakpoints } from './breakpoints/breakpoints';
export var defaultTheme = {
    colors: __assign({}, colors),
    typo: __assign({}, typography),
    spaces: __assign({}, spaces),
    borders: __assign({}, borders),
    elements: __assign({}, elements),
    text: __assign({}, defaultText),
    behavior: __assign({}, behavior),
    breakpoints: __assign({}, breakpoints)
};
var overwriteMerge = function (objValue, srcValue) {
    if (isArray(objValue)) {
        return srcValue;
    }
};
function fontAsArray(font) {
    if (Array.isArray(font)) {
        return font;
    }
    return [font];
}
export function createTheme(theme) {
    var newTheme = cloneDeep(defaultTheme);
    newTheme.text = text(theme);
    Object.keys(theme).forEach(function (themeProperty) {
        newTheme[themeProperty] = mergeWith(newTheme[themeProperty], theme[themeProperty], overwriteMerge);
    });
    // Format injected fonts as array - required so that it can be processed by Aphrodite
    Object.keys(newTheme.typo.fonts).map(function (index) {
        newTheme.typo.fonts[index] = fontAsArray(newTheme.typo.fonts[index]);
    });
    domUtils.addFontFacesBasedOnTheme(newTheme.typo.fonts);
    return newTheme;
}
export function mergeWithComponentCustomizedStyles(theme, component, componentStyles) {
    return merge(componentStyles, get(theme, "customizedStyles.conversionalComponents.".concat(component), {}));
}
