var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a, _b;
import get from 'lodash/get';
import merge from 'lodash/merge';
// 10 seconds of retrying iframe creation for times target may not be in the page at the execution time
// Twice per second, 20 times
var INLINE_IFRAME_CREATION_TRY_TIMEOUT_DELAY = 500;
var INLINE_IFRAME_CREATION_TRY_ITERATIONS = 20;
// @ts-ignore
import * as configUtils from 'conversional-utils/config';
import { isUrlAllowed } from "conversional-iframe-dom";
import { captureException } from 'conversional-exceptions';
import Manager from './Manager';
import * as iframeTemplates from '../constants/iframeTemplates';
import * as iframeIds from '../constants/iframeIds';
/**
 * Creators generate Class-wrapped iframes to connect them with the communication and messaging interface.
 * The task of the creators is to translate journeyConfiguration into a numer of configured iframes
 * The control flow is:
 *
 * 1. parse journey config
 * 2. check for the presence of configuration for alle default_config iframes
 * 3. create iframes
 * 4. return a promise that resolves once all iframes are content-ready
 *
 */
var DYNAMIC_CONTENT_PATH = 'windows.dynamic';
/**
 * Iframes are created using DEFAULT_CONFIG (= the list of iframes that are checked for by default without any special events) and
 * CREATORS: the functions responsible for transforming DEFAULT_CONFIG into a value that can be processed by the iframe Managers constrcutor
 */
var DEFAULT_CONFIG = (_a = {},
    _a[iframeTemplates.LAUNCHER] = {
        id: iframeIds.LAUNCHER,
        template: iframeTemplates.LAUNCHER,
        viewPropsPath: 'callToAction.complexLoader'
    },
    _a[iframeTemplates.ENTRY] = {
        id: iframeIds.ENTRY,
        template: iframeTemplates.ENTRY,
        viewPropsPath: 'entry'
    },
    _a[iframeTemplates.RESULT] = {
        id: iframeIds.RESULT,
        template: iframeTemplates.RESULT,
        viewPropsPath: undefined
    },
    _a[iframeTemplates.EXIT_INTENT] = {
        id: iframeIds.EXIT_INTENT,
        template: iframeTemplates.EXIT_INTENT,
        viewPropsPath: 'callToAction.exitIntent'
    },
    _a[iframeTemplates.EXIT_INTENT_MOBILE] = {
        id: iframeIds.EXIT_INTENT_MOBILE,
        template: iframeTemplates.EXIT_INTENT_MOBILE,
        viewPropsPath: 'callToAction.exitIntentMobile'
    },
    _a[iframeTemplates.TRUST_PULSE] = {
        id: iframeIds.TRUST_PULSE,
        template: iframeTemplates.TRUST_PULSE,
        viewPropsPath: 'callToAction.trustPulse'
    },
    _a);
var checkTargetForIframe = function (_a) {
    var targetId = _a.targetId, iteration = _a.iteration, resolve = _a.resolve, creator = _a.creator;
    if (targetId && !document.getElementById(targetId)) {
        if (iteration === INLINE_IFRAME_CREATION_TRY_ITERATIONS) {
            captureException(new Error("Target: ".concat(targetId, " is not available for creating the iframe")), {
                targetId: targetId
            }, {
                host: true
            });
            resolve();
        }
        else {
            setTimeout(creator, INLINE_IFRAME_CREATION_TRY_TIMEOUT_DELAY);
        }
        return false;
    }
    return true;
};
var CREATORS = (_b = {},
    _b[iframeTemplates.RESULT] = createResult,
    _b);
function getCreator(type) {
    return CREATORS[type] || defaultCreator;
}
/**
 * Will extract configuration from journeyConfig depending on the type of iframe and its DEFAULT_CONFIG
 * @param  {journeyConfig} config
 * @param  {string} type
 * @returns IframeConfig
 */
function extractIframeConfig(config, type) {
    var defaultConfig = DEFAULT_CONFIG[type];
    if (!defaultConfig) {
        return {};
    }
    // The config values are nested within the viewProps because the current backend API could not provider a higher level
    var _a = defaultConfig.viewPropsPath
        ? get(config, defaultConfig.viewPropsPath, {})
        : {}, _b = _a.Window, Window = _b === void 0 ? {} : _b, viewProps = __rest(_a, ["Window"]);
    var target = Window.target, template = Window.template, callbacks = Window.callbacks, iframeStyles = Window.iframeStyles, hostRawStyles = Window.hostRawStyles;
    return {
        id: defaultConfig.id,
        viewConfig: {
            template: template || defaultConfig.template || undefined,
            abTest: configUtils.getAbTest(),
            callbacks: callbacks,
            iframeStyles: iframeStyles,
            hostRawStyles: hostRawStyles,
        },
        viewProps: viewProps,
        target: target
    };
}
function createResult(config, onReady, type, settings) {
    if (onReady === void 0) { onReady = function () {
    }; }
    var _a = extractIframeConfig(config, type), id = _a.id, viewConfig = _a.viewConfig, viewProps = _a.viewProps;
    return new Manager(id, viewConfig, viewProps, config, onReady);
}
/**
 * Create special dynamic iframe, usuallly triggered through URL
 * @param  {journeyConfig} config
 * @param  {string} token
 */
export function createDynamicIframe(config, token, contentParams) {
    return new Promise(function (resolve) {
        if (shouldBlockIframe(iframeTemplates.DYNAMIC, config))
            return resolve();
        var id = "iframe-conversional-dynamic-".concat(token);
        var viewConfig = {
            template: iframeTemplates.DYNAMIC,
            abTest: configUtils.getAbTest()
        };
        var viewProps = {
            token: token,
            contentParams: contentParams
        };
        var target = get(config, DYNAMIC_CONTENT_PATH, 'conversional');
        var dynamicIframe = new Manager(id, viewConfig, viewProps, config, resolve, target);
        if (!dynamicIframe)
            resolve();
    });
}
var getIframeSettings = function (type, config) {
    var iframesSettings = merge(config.iframes, get(window, 'conversionalWidget.config.iframes', {}));
    return get(iframesSettings, type, get(iframesSettings, type.toLowerCase()));
};
export var shouldBlockIframe = function (type, config) {
    var iframeSettings = getIframeSettings(type, config);
    if (typeof iframeSettings === "boolean" && iframeSettings === false)
        return true;
    if (typeof iframeSettings === "object") {
        var blackList = get(iframeSettings, 'blackList', []);
        var whiteList = get(iframeSettings, 'whiteList', []);
        return !isUrlAllowed(blackList, whiteList, window.location.href);
    }
    return false;
};
// Since in the flash mode, everything is tried to be done in parallel, the iframes object may not be there initially
// The retry is by means of checking the objects to be available in some time.
var shouldRetryIframeCreation = function (settings, type) {
    return !window.conversionalLauncher.lazy && get(settings, 'iframes', "").split(",").includes(type.toLowerCase());
};
function createIframe(config, type, settings) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            /**
             * If based on configuration, we are not allowed to create a particular
             * iframe, we will return false,
             * */
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    if (shouldBlockIframe(type, config))
                        return resolve(1);
                    var iframeSettings = getIframeSettings(type, config);
                    var iframeCreationDelay = (iframeSettings === null || iframeSettings === void 0 ? void 0 : iframeSettings.delay) || 0;
                    if (iframeCreationDelay > 0)
                        resolve(1);
                    setTimeout(function () {
                        var creator = getCreator(type);
                        var iframe = creator(config, resolve, type, settings);
                        if (!iframe)
                            resolve(iframe);
                    }, iframeCreationDelay);
                })];
        });
    });
}
/**
 * Standard creator for all kinds of iframes
 */
function defaultCreator(config, onReady, type, settings, iteration) {
    if (onReady === void 0) { onReady = function () {
    }; }
    if (iteration === void 0) { iteration = -1; }
    var _a = extractIframeConfig(config, type), id = _a.id, viewConfig = _a.viewConfig, viewProps = _a.viewProps, target = _a.target;
    var check = checkTargetForIframe({
        targetId: target,
        iteration: ++iteration,
        resolve: function () { },
        creator: function () { return defaultCreator(config, onReady, type, settings, iteration); }
    });
    if (!check)
        return;
    if (!viewProps || Object.keys(viewProps).length === 0) {
        // Retry in flash mode to make sure the data will be there
        if (shouldRetryIframeCreation(settings, type))
            setTimeout(function () { return defaultCreator(config, onReady, type, settings); });
        return;
    }
    return new Manager(id, viewConfig, viewProps, config, onReady, target);
}
/**
 * Arbitrary creator
 */
function arbitraryCreator(arbitraryIframe, journeyConfig, iteration) {
    if (iteration === void 0) { iteration = -1; }
    return new Promise(function (resolve) {
        var _a = arbitraryIframe.Window || {}, target = _a.target, callbacks = _a.callbacks, iframeStyles = _a.iframeStyles, bodyStyles = _a.bodyStyles, hostRawStyles = _a.hostRawStyles;
        var externalData = arbitraryIframe.externalData;
        var check = checkTargetForIframe({
            targetId: target,
            iteration: ++iteration,
            resolve: resolve,
            creator: function () { return arbitraryCreator(arbitraryIframe, journeyConfig, iteration).then(resolve); }
        });
        if (!check)
            return;
        var id = "".concat(iframeIds.ARBITRARY, "-").concat(arbitraryIframe.id);
        var viewProps = {
            content: arbitraryIframe.content,
            externalData: externalData
        };
        var viewConfig = {
            template: target ? iframeTemplates.INLINE : iframeTemplates.ARBITRARY,
            iframeStyles: iframeStyles,
            bodyStyles: bodyStyles,
            hostRawStyles: hostRawStyles,
            callbacks: callbacks,
        };
        var iframe = new Manager(id, viewConfig, viewProps, journeyConfig, resolve, target);
        if (!iframe) {
            captureException(new Error("Failed to create arbitrary iframe with target: ".concat(target)), {
                target: target,
                viewConfig: viewConfig,
                viewProps: viewProps,
                id: id
            }, {
                host: true
            });
            return resolve();
        }
    });
}
function createArbitraryIframes(config) {
    return (config.elements || []).map(function (iframeConfig) { return arbitraryCreator(iframeConfig, config); });
}
/**
 * Creates all iframes and returns a promise that resolves when all iframes are content-ready
 * @param  {journeyConfig} config
 */
export function createAllIframes(config, settings) {
    var promises = Object.keys(DEFAULT_CONFIG).map(function (type) {
        return createIframe(config, type, settings);
    });
    promises.concat(createArbitraryIframes(config));
    return promises;
}
