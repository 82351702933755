var CONFIG_ELEMENT_ID = 'conversional-journey';
export var getJourneySettings = function () {
    var config = {
        api: null,
        version: null,
        key: null,
        journey: null,
        noCookies: null,
        result: null,
        bootstrap: null,
    };
    var configElement = document.getElementById(CONFIG_ELEMENT_ID);
    for (var _i = 0, _a = Object.keys(config); _i < _a.length; _i++) {
        var key = _a[_i];
        if (configElement) {
            var configValue = configElement.getAttribute("data-" + key);
            if (configValue) {
                config[key] = configValue;
            }
        }
    }
    return config;
};
