import { urls } from 'conversional-environment';
// @ts-ignore
import * as domUtils from "conversional-utils/dom";
// @ts-ignore
import * as iframeDomUtils from "conversional-utils/iframeDom";
import { urlHelpers } from "conversional-api";
import { setInlineLoading, setLoading } from "conversional-iframe-dom";
import DOMListener from './DomListener';
import { handleMessage } from './hostMessageHandler';
import * as MESSAGETYPES from '../constants/messageTypes';
import formatMessage from '../formatMessage';
import getHub from '../messageHub';
import { DEFAULT_IFRAME_STYLE, mixStyles } from './styles';
import getPath from "../constants/path";
import * as iframeTemplates from "../constants/iframeTemplates";
var DEFAULT_SUBSCRIPTIONS = [
    MESSAGETYPES.SET_EXTERNAL,
    MESSAGETYPES.RPC_SET_EXTERNAL,
    MESSAGETYPES.RPC_CLEAR_CACHE,
    MESSAGETYPES.JOURNEY_SHOW,
    MESSAGETYPES.SET_SHARED_STORE,
    MESSAGETYPES.STORAGE_CONSENT_ACTIVATE,
    MESSAGETYPES.STORAGE_CONSENT_DEACTIVATE
];
/**
 * The Manager is a wrapper around each iframe.
 * It establishes a communication link for all the things an iframe cannot to itself, e.g.
 * setting its own styles, changing host body styles, sending messages, listening to DOM events.
 *
 * The iframe is created in multiple steps:
 * 1. process the config and add the iframe to the DOM
 * 2. On ready message from the iframe: Pass content to the iframe via PostMessage
 * 3. On content ready, use callback function to inform outside that this iframe is ready to receive events
 *
 * One of the most important task of the manager is to make sure one manager only reacts to and talks to a single specific iframe, ignoring all other messages.
 */
var Manager = /** @class */ (function () {
    function Manager(id, viewConfig, viewProps, journey, onReady, target) {
        if (target === void 0) { target = null; }
        var _this = this;
        this.ready = function () {
            _this.toIframe(MESSAGETYPES.SET_CONTENT, {
                viewProps: _this.viewProps,
                viewConfig: _this.viewConfig,
                journey: _this.journey,
                windowWidth: window.innerWidth,
                conversionalLauncherHostObject: window.conversionalLauncher,
                urlParams: urlHelpers.parseQueryString()
            });
            // Some events should always be received by any iframe
            DEFAULT_SUBSCRIPTIONS.map(function (event) {
                _this.subscribe(event);
            });
            _this.applyHostRawStyles();
        };
        // Content ready means the outside can now interact with the iframe through the messageHub
        this.contentReady = function () {
            _this.isReady = true;
            _this.onReadyCallback();
        };
        this.onMessage = function (message) {
            // If iframe is not created, skip messages coming from other sources
            if (!_this.reference) {
                return;
            }
            // @ts-ignore
            if (message.source !== _this.reference.contentWindow) {
                return;
            }
            // @ts-ignore
            handleMessage(message || '', _this);
        };
        this.subscribe = function (topic) {
            getHub().subscribe(topic, _this.toIframe);
            var fragments = topic.split('.');
            if (fragments[0] === MESSAGETYPES.ON) {
                DOMListener.add(topic, function () { return getHub().publish(topic); });
            }
        };
        this.destroy = function () {
            if (!_this.isReady) {
                _this.onReadyCallback();
            }
            getHub().unsubscribeFromAll(_this.toIframe);
            _this.removeDisabledZoomMetaTag();
            _this.reference.remove();
        };
        this.blur = function () {
            _this.removeDisabledZoomMetaTag();
        };
        this.onScreen = function () {
            var _a;
            var iframeTemplate = (_a = _this.viewConfig) === null || _a === void 0 ? void 0 : _a.template;
            switch (iframeTemplate) {
                case iframeTemplates.ENTRY:
                case iframeTemplates.EXIT_INTENT:
                case iframeTemplates.RESULT:
                    return setLoading(false);
                case iframeTemplates.INLINE:
                    return setInlineLoading(false);
            }
        };
        this.offScreen = function () {
        };
        this.style = function (_a) {
            var styles = _a.styles, replace = _a.replace;
            var _b = mixStyles(_this.styles, styles, replace), className = _b.className, rawStyles = _b.rawStyles;
            _this.reference.className = className;
            _this.styles = rawStyles;
        };
        this.styleTargetContainer = function (_a) {
            var styles = _a.styles, replace = _a.replace;
            var targetElement = document.getElementById(_this.target);
            if (!targetElement)
                return;
            var className = mixStyles(targetElement.style, styles, replace).className;
            targetElement.className = className;
        };
        this.toIframe = function (type, message) {
            var stringifiedMessage = formatMessage(type, message);
            if (_this.reference.contentWindow) {
                _this.reference.contentWindow.postMessage(stringifiedMessage, urls.widgetUrl);
            }
        };
        this.unsubscribe = function (topic) {
            getHub().unsubscribe(topic, _this.toIframe);
            var fragments = topic.split('.');
            if (fragments[0] === MESSAGETYPES.OFF) {
                DOMListener.add(topic, function () { return getHub().publish(topic); });
            }
        };
        this.publish = function (type, message) {
            getHub().publish(type, message);
        };
        this.id = id;
        this.isReady = false;
        this.journey = journey;
        this.target = target;
        this.styles = DEFAULT_IFRAME_STYLE;
        this.onReadyCallback = onReady;
        this.viewConfig = viewConfig;
        this.viewProps = viewProps;
        window.addEventListener('message', this.onMessage);
        this.reference = this.create();
    }
    Manager.prototype.create = function () {
        var className = mixStyles({}, DEFAULT_IFRAME_STYLE, true).className;
        var iframeData = {
            id: this.id,
            className: className,
            src: "".concat(urls.widgetUrl).concat(getPath(), "&iframeId=").concat(this.id),
            targetId: this.target,
        };
        return iframeDomUtils.addIframeToDOM(iframeData);
    };
    Manager.prototype.applyHostRawStyles = function () {
        var hostRawStyles = this.viewConfig.hostRawStyles;
        hostRawStyles && domUtils.addResponsiveRawStyles(hostRawStyles, "style-".concat(this.id));
    };
    Manager.prototype.focus = function () {
        if (this.reference.contentWindow)
            this.reference.contentWindow.focus();
        this.disableZoom();
    };
    // region Handle Scaling
    /**
     * Add viewport meta tag to the header of the host to disable zoom on touch devices
     * */
    Manager.prototype.disableZoom = function () {
        this.viewPortScalingMetaId = domUtils.addMetaTagForScaling();
    };
    Manager.prototype.removeDisabledZoomMetaTag = function () {
        if (this.viewPortScalingMetaId)
            domUtils.removeElementById(this.viewPortScalingMetaId);
    };
    return Manager;
}());
export default Manager;
