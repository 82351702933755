var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getItem, setItem } from 'conversional-persistent-storage';
// @ts-ignore
import * as objectUtil from 'conversional-utils/object';
import { parseQueryString } from './dom/utils';
import { URLParamBuilder } from './functions';
// Constants
var JOURNEY_CONFIG_CACHE_TTL = 3 * 60 * 1000;
// Private Methods
var env = process.env.REACT_APP_JOURNEY_ENV;
var checkJourneyConfigChange = function (journey, config) {
    var previousJourneyConfig = getLocalJourneyConfig(journey, {
        prefixed: true,
    });
    var rawPreviousJourneyConfig = JSON.stringify(__assign(__assign({}, JSON.parse(previousJourneyConfig)), { updated: undefined }));
    config.updated =
        JSON.stringify(__assign(__assign({}, config), { updated: undefined })) !== rawPreviousJourneyConfig;
    return config;
};
var performAPICall = function (options) {
    if (options === void 0) { options = {
        method: '',
        url: '',
    }; }
    var method = options.method, url = options.url;
    return new Promise(function (resolve, reject) {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () { return processAPIResponse(xhttp, resolve, reject); };
        xhttp.open(method, url, true);
        xhttp.send();
    });
};
// eslint-disable-next-line no-empty-pattern
function processAPIResponse(xhttp, resolve, reject) {
    if (xhttp.readyState !== 4)
        return;
    var response;
    try {
        response = JSON.parse(xhttp.responseText);
    }
    catch (e) {
        response = xhttp.responseText;
    }
    if (xhttp.status === 200)
        resolve(response);
    if (xhttp.status >= 400)
        reject(xhttp);
}
var getLocalJourneyConfig = function (journey, options) {
    if (options === void 0) { options = {}; }
    var cacheKey = 'raw-' + journey;
    return getItem(cacheKey, options);
};
var setLocalJourneyConfig = function (journey, config, options) {
    if (options === void 0) { options = {}; }
    if (!isCachingEnabled())
        return;
    var cacheKey = 'raw-' + journey;
    return setItem(cacheKey, config, options);
};
var fetchNonCachedJourneyConfig = function (settings, journey) { return __awaiter(void 0, void 0, Promise, function () {
    var journeyConfig, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, fetchJourneyConfig(settings)];
            case 1:
                journeyConfig = _a.sent();
                journeyConfig = checkJourneyConfigChange(journey, journeyConfig);
                setLocalJourneyConfig(journey, JSON.stringify(journeyConfig), {
                    ttl: JOURNEY_CONFIG_CACHE_TTL,
                    prefixed: true,
                });
                return [2 /*return*/, journeyConfig];
            case 2:
                error_1 = _a.sent();
                throw error_1;
            case 3: return [2 /*return*/];
        }
    });
}); };
var fetchMockJourneyConfig = function () { return __awaiter(void 0, void 0, Promise, function () {
    var url, selectedDevConfiguration, selectedLanguage, response, configuration, mockJourneyConfig, error_2;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                url = 'http://localhost:7001/api/mock';
                selectedDevConfiguration = localStorage.getItem('selectedDevConfiguration');
                selectedLanguage = localStorage.getItem('selectedDevLanguage');
                if (selectedDevConfiguration) {
                    url += "?config=".concat(selectedDevConfiguration);
                    if (selectedLanguage !== '-1') {
                        url += "&language=".concat(selectedLanguage);
                    }
                }
                if ((_a = parseQueryString()) === null || _a === void 0 ? void 0 : _a.abTest)
                    url += "&abTest=".concat((_b = parseQueryString()) === null || _b === void 0 ? void 0 : _b.abTest);
                _c.label = 1;
            case 1:
                _c.trys.push([1, 3, , 4]);
                return [4 /*yield*/, performAPICall({
                        method: 'GET',
                        url: url,
                    })];
            case 2:
                response = _c.sent();
                configuration = objectUtil.resolveDefinitions(response.data.configuration);
                mockJourneyConfig = __assign(__assign({}, response.data), { configuration: configuration, abTest: response.data.abTest || null });
                return [2 /*return*/, mockJourneyConfig];
            case 3:
                error_2 = _c.sent();
                if (error_2.status === 404) {
                    localStorage.removeItem('selectedDevConfiguration');
                    window.location.reload();
                }
                throw error_2;
            case 4: return [2 /*return*/];
        }
    });
}); };
var fetchRealJourneyConfig = function (config) { return __awaiter(void 0, void 0, Promise, function () {
    var queryString, pageQueryString, response, configuration, realJourneyConfig, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                queryString = {
                    apiKey: config.key
                };
                pageQueryString = parseQueryString();
                if (pageQueryString === null || pageQueryString === void 0 ? void 0 : pageQueryString.draft)
                    queryString.draft = 1;
                if (pageQueryString === null || pageQueryString === void 0 ? void 0 : pageQueryString.abTest)
                    queryString.abTest = pageQueryString.abTest;
                return [4 /*yield*/, performAPICall({
                        method: 'GET',
                        url: URLParamBuilder(':version/journeys/:journey', {
                            root: config.api,
                            params: {
                                version: config.version,
                                journey: config.journey,
                            },
                            queryString: queryString,
                        }),
                    })];
            case 1:
                response = _a.sent();
                configuration = objectUtil.resolveDefinitions(response.data.configuration);
                realJourneyConfig = __assign(__assign({}, response.data), { configuration: configuration, abTest: response.data.abTest || null });
                return [2 /*return*/, realJourneyConfig];
            case 2:
                error_3 = _a.sent();
                throw error_3;
            case 3: return [2 /*return*/];
        }
    });
}); };
// Private Methods
var isCachingEnabled = function () { var _a; return !['development', 'remote'].includes(env) && !((_a = parseQueryString()) === null || _a === void 0 ? void 0 : _a.abTest); };
export var getJourneyConfig = function (settings) { return __awaiter(void 0, void 0, void 0, function () {
    var journey, fetchedJourneyConfigFromCache, journeyConfig, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                journey = settings.journey;
                fetchedJourneyConfigFromCache = getLocalJourneyConfig(journey, {
                    ttl: JOURNEY_CONFIG_CACHE_TTL,
                    prefixed: true,
                });
                if (fetchedJourneyConfigFromCache && isCachingEnabled()) {
                    journeyConfig = JSON.parse(fetchedJourneyConfigFromCache);
                    return [2 /*return*/, journeyConfig];
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, fetchNonCachedJourneyConfig(settings, journey)];
            case 2:
                journeyConfig = _a.sent();
                return [3 /*break*/, 4];
            case 3:
                error_4 = _a.sent();
                throw error_4;
            case 4: return [2 /*return*/, journeyConfig];
        }
    });
}); };
export var fetchJourneyConfig = function (config) { return __awaiter(void 0, void 0, Promise, function () {
    var mockJourneyConfig, realJourneyConfig;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(env === 'development')) return [3 /*break*/, 2];
                return [4 /*yield*/, fetchMockJourneyConfig()];
            case 1:
                mockJourneyConfig = _a.sent();
                return [2 /*return*/, mockJourneyConfig];
            case 2: return [4 /*yield*/, fetchRealJourneyConfig(config)];
            case 3:
                realJourneyConfig = _a.sent();
                return [2 /*return*/, realJourneyConfig];
        }
    });
}); };
