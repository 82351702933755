export var EXIT_INTENT = 'EXIT_INTENT';
export var EXIT_INTENT_MOBILE = 'EXIT_INTENT_MOBILE';
export var RESULT = 'RESULT';
export var ENTRY = 'ENTRY';
export var LAUNCHER = 'LAUNCHER';
export var TRUST_PULSE = 'TRUST_PULSE';
export var INLINE = 'INLINE';
export var DYNAMIC = 'DYNAMIC';
export var PREVIEW = 'PREVIEW';
export var ARBITRARY = 'ARBITRARY';
export var PRELOADER = 'PRELOADER';
