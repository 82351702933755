import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { mediaBreakpointBetween, mediaBreakpointDown, mediaBreakpointUp, sizes } from "conversional-theme";
export var addStyleToHead = function (href, id) {
    if (id === void 0) { id = ''; }
    if (id && document.getElementById(id)) {
        return;
    }
    var Link = document.createElement('link');
    Link.rel = 'stylesheet';
    Link.href = href;
    if (id)
        Link.id = id;
    document.head.appendChild(Link);
};
function convertRemToPixels(rem) {
    if (typeof rem === "string")
        rem = parseFloat(rem);
    return Math.round(rem * parseFloat(getComputedStyle(document.documentElement).fontSize));
}
function getPixelValue(value) {
    if (value.includes("px"))
        return parseInt(value.split("px")[0]);
    if (value.includes("rem"))
        return convertRemToPixels(value.split("rem")[0]);
    return undefined;
}
export var getComputedStylesWidth = function (element) {
    var styles = window.getComputedStyle(element);
    if (styles.width) {
        if (getPixelValue(styles.width) > 0)
            return getPixelValue(styles.width);
    }
    if (styles.maxWidth) {
        if (getPixelValue(styles.maxWidth) > 0)
            return getPixelValue(styles.maxWidth);
    }
    if (element.offsetWidth)
        return element.offsetWidth;
};
export var addFontPreload = function (url) {
    var Link = document.createElement('link');
    Link.rel = 'preload';
    Link.href = url;
    Link.as = 'font';
    Link.crossOrigin = 'anonymous';
    document.head.appendChild(Link);
};
var addStyle = function (style, elementId) {
    var Style = document.createElement('style');
    Style.innerText = style;
    if (elementId)
        Style.id = elementId;
    document.head.appendChild(Style);
};
var getFontFacesBasedOnTheme = function (fonts) {
    var styles = "";
    Object.keys(fonts).forEach(function (fontKey) {
        var value = fonts[fontKey];
        if (!isEmpty(value)) {
            var font = Array.isArray(value)
                ? value[0]
                : value;
            styles += "\n                @font-face{\n                    font-family: \"".concat(font.fontFamily, "\";\n                    src: ").concat(font.src, ";\n                    ").concat(font.fontWeight ? "font-weight: ".concat(font.fontWeight, ";") : "", "\n                }\n            ").replace(/(\r\n|\n|\r)/gm, '');
        }
    });
    return styles;
};
export var addFontFacesBasedOnTheme = function (fonts) {
    addStyle(getFontFacesBasedOnTheme(fonts));
};
export var addMetaTagForScaling = function () {
    var id = "conversional-scale-meta-tag";
    var meta = document.createElement("meta");
    // This tells browser that panning is not available in mobile devices
    meta.setAttribute("name", "viewport");
    meta.setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no");
    meta.id = id;
    document.head.appendChild(meta);
    return id;
};
export var removeElementById = function (id) {
    if (!id)
        return;
    var element = document.getElementById(id);
    if (!element)
        return;
    element.remove();
};
export var getDefaultCookieDomain = function () { return ".".concat(location.host || location.hostname); };
export var deleteCookie = function (name, path, domain) {
    if (path === void 0) { path = "/"; }
    if (domain === void 0) { domain = getDefaultCookieDomain(); }
    if (getCookie(name)) {
        document.cookie = name + "=" +
            ((path) ? ";path=" + path : "") +
            ((domain) ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
};
function getCookie(name) {
    return document.cookie.split(';').some(function (c) {
        return c.trim().startsWith(name + '=');
    });
}
export var waitForAllImagesToLoad = function () {
    return new Promise(function (resolve) {
        var images = document.images, len = images.length;
        var counter = 0;
        var incrementCounter = function () {
            if (++counter === len)
                resolve(true);
        };
        [].forEach.call(images, function (img) {
            if (img.complete)
                incrementCounter();
            else
                img.addEventListener('load', incrementCounter, false);
        });
    });
};
/**
 * This function enables us to change host's page title in two ways
 * 1. Static page title, set once
 * 2. Using an interval to change to the original title every x seconds
 *
 * Currently being used in:
 * - Exit intent
 * */
export var triggerPageTitleChange = function (pageTitleTrigger) {
    if (get(pageTitleTrigger, 'enable') === undefined)
        return;
    var title = pageTitleTrigger.title, interval = pageTitleTrigger.interval, enable = pageTitleTrigger.enable;
    if (!title)
        return;
    if (enable) {
        window.previousTitle = "".concat(document.title);
        document.title = title;
        if (interval)
            window.conversionalPageTitleChangeInterval = setInterval(function () {
                document.title = document.title === title ? window.previousTitle : title;
            }, interval);
    }
    else {
        clearInterval(window.conversionalPageTitleChangeInterval);
        document.title = window.previousTitle || document.title;
        delete window.previousTitle;
    }
};
export var addResponsiveRawStyles = function (styles, styleElementId) {
    if (styles === void 0) { styles = {}; }
    var rawCSSStyles = "";
    if (styles.general)
        rawCSSStyles += styles.general;
    if (styles.desktop)
        rawCSSStyles += "".concat(mediaBreakpointUp(sizes.md), "{").concat(styles.desktop, "}");
    if (styles.tablet)
        rawCSSStyles += "".concat(mediaBreakpointBetween(sizes.sm, sizes.md), "{").concat(styles.tablet, "}");
    if (styles.mobile)
        rawCSSStyles += "".concat(mediaBreakpointDown(sizes.xs), "{").concat(styles.mobile, "}");
    if (rawCSSStyles)
        addStyle(rawCSSStyles, styleElementId);
};
export var addStyleTag = function (payload) {
    var elementId = payload.elementId, styles = payload.styles;
    if (elementId && document.getElementById(elementId))
        return;
    addStyle(styles, elementId);
};
var addClassNameToElement = function (element, className) {
    return element && className && element.classList.add(className);
};
var removeClassNameFromElement = function (element, className) {
    return element && className && element.classList.remove(className);
};
export var addClassNameToBody = function (className) { return addClassNameToElement(document.body, className); };
export var removeClassNameFromBody = function (className) { return removeClassNameFromElement(document.body, className); };
export var addClassNameToElementsBySelectors = function (className, selectors) {
    selectors.forEach(function (selector) {
        var elements = document.querySelectorAll(selector);
        elements.forEach(function (element) { return addClassNameToElement(element, className); });
    });
};
export var removeClassNameFromElementsBySelectors = function (className, selectors) {
    selectors.forEach(function (selector) {
        var elements = document.querySelectorAll(selector);
        elements.forEach(function (element) { return removeClassNameFromElement(element, className); });
    });
};
