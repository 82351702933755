var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { sizes, mediaBreakpointDown, mediaBreakpointUp, mediaBreakpointBetween } from '../sizes/sizes';
import { StyleSheet, css } from 'aphrodite/no-important';
// TODO: Refactor then general handling of themes
// Since the introduction of iframes it is no longer necessary to server a dummy theme, we can expect the real theme to be loaded
export var defaultText = {
    h1: {},
    h2: {},
    h3: {},
    h4: {},
    h5: {},
    h6: {},
    link: {}
};
export var text = function (theme) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (!theme || !theme.typo || !theme.typo.sizes || !theme.typo.sizes.xxlMobile)
        return defaultText;
    return {
        h1: (_a = {},
            _a[mediaBreakpointDown(sizes.sm)] = {
                fontSize: theme.typo.sizes.xxlMobile
            },
            _a[mediaBreakpointUp(sizes.md)] = {
                fontSize: theme.typo.sizes.xxl
            },
            _a),
        h2: (_b = {},
            _b[mediaBreakpointDown(sizes.sm)] = {
                fontSize: theme.typo.sizes.xlMobile
            },
            _b[mediaBreakpointUp(sizes.md)] = {
                fontSize: theme.typo.sizes.xl
            },
            _b),
        h3: (_c = {},
            _c[mediaBreakpointDown(sizes.sm)] = {
                fontSize: theme.typo.sizes.lMobile
            },
            _c[mediaBreakpointUp(sizes.md)] = {
                fontSize: theme.typo.sizes.l
            },
            _c),
        h4: (_d = {},
            _d[mediaBreakpointDown(sizes.sm)] = {
                fontSize: theme.typo.sizes.mMobile
            },
            _d[mediaBreakpointUp(sizes.md)] = {
                fontSize: theme.typo.sizes.m
            },
            _d),
        h5: (_e = {},
            _e[mediaBreakpointDown(sizes.sm)] = {
                fontSize: theme.typo.sizes.msMobile
            },
            _e[mediaBreakpointUp(sizes.md)] = {
                fontSize: theme.typo.sizes.ms
            },
            _e),
        h6: (_f = {},
            _f[mediaBreakpointDown(sizes.sm)] = {
                fontSize: theme.typo.sizes.sMobile
            },
            _f[mediaBreakpointUp(sizes.md)] = {
                fontSize: theme.typo.sizes.s
            },
            _f),
        link: (_g = {},
            _g[mediaBreakpointDown(sizes.sm)] = {
                fontSize: theme.typo.sizes.mMobile
            },
            _g[mediaBreakpointUp(sizes.md)] = {
                fontSize: theme.typo.sizes.m
            },
            _g)
    };
};
export var areas = function (theme) {
    var _a;
    return ({
        section: {},
        root: (_a = {},
            _a[mediaBreakpointDown(sizes.sm)] = {
                backgroundColor: theme && theme.colors.backgroundLight
            },
            _a)
    });
};
export var images = function () { return ({
    responsive: {
        width: '100%',
        height: 'auto'
    }
}); };
export var behavior = {
    hideOnMobile: (_a = {},
        _a[mediaBreakpointDown(sizes.sm)] = {
            display: 'none'
        },
        _a)
};
export var GeneralStyles = function (styleProps) {
    if (!styleProps) {
        return {};
    }
    return __assign({}, styleProps);
};
export var DesktopStyles = function (styleProps) {
    var _a;
    if (!styleProps) {
        return {};
    }
    return _a = {},
        _a[mediaBreakpointUp(sizes.md)] = __assign({}, styleProps),
        _a;
};
export var TabletStyles = function (styleProps) {
    var _a;
    if (!styleProps) {
        return {};
    }
    return _a = {},
        _a[mediaBreakpointBetween(sizes.sm, sizes.md)] = __assign({}, styleProps),
        _a;
};
export var MobileStyles = function (styleProps) {
    var _a;
    if (!styleProps) {
        return {};
    }
    return _a = {},
        _a[mediaBreakpointDown(sizes.xs)] = __assign({}, styleProps),
        _a;
};
// Creates classes based on component-specific styles
export var ComponentStyles = function (injectedStyles) {
    if (injectedStyles === void 0) { injectedStyles = {}; }
    var general = injectedStyles.general, mobile = injectedStyles.mobile, desktop = injectedStyles.desktop, tablet = injectedStyles.tablet, direct = __rest(injectedStyles, ["general", "mobile", "desktop", "tablet"]);
    return StyleSheet.create({
        // could not get correct types for this to work
        // @ts-ignore
        custom: __assign(__assign(__assign(__assign(__assign({}, direct), GeneralStyles(__assign({}, general))), DesktopStyles(desktop)), TabletStyles(tablet)), MobileStyles(mobile))
    }).custom;
};
export var ComponentClass = function (injectedStyles) {
    if (injectedStyles === void 0) { injectedStyles = {}; }
    return css(ComponentStyles(injectedStyles));
};
