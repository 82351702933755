var _a;
import get from 'lodash/get';
import * as listeners from 'conversional-listeners';
import * as MESSAGETYPES from '../constants/messageTypes';
var monitorLeaveSite = listeners.monitorLeaveSite, monitorIdle = listeners.monitorIdle, monitorMobileExit = listeners.monitorMobileExit;
var EXIT_INTENT_IDLE_ENABLED_KEY = 'conversionalLauncher.journeyConfig.configuration.callToAction.exitIntent.triggerOnIdle';
// Listener Handlers add listeners to the DOM and return a function to remove listeners
// LIstener Handlers can orchestrate multiple listeners, e.g. if multiple events should trigger the same listener
var LISTENER_HANDLERS = (_a = {},
    _a[MESSAGETYPES.ON_EXITMOBILE] = function (callback) {
        return monitorMobileExit(callback);
    },
    _a[MESSAGETYPES.ON_EXIT] = function (callback) {
        var cleanup1 = monitorLeaveSite(callback);
        var idleEnabled = get(window, EXIT_INTENT_IDLE_ENABLED_KEY, true);
        var cleanup2 = idleEnabled && monitorIdle(callback);
        return function () {
            cleanup1();
            cleanup2 && cleanup2();
        };
    },
    _a.default = function () {
        return function () { };
    },
    _a);
function getHandler(type) {
    return LISTENER_HANDLERS[type] || LISTENER_HANDLERS['default'];
}
/*
The DOMListener attaches DOM listeners and triggers the passed callback (publish)
It only attaches a single listener and keeps track of the current amount of listeners
If the last listener unsubscribed it will remove the DOM listener
 */
var DOMListener = (function () {
    var activeListeners = {};
    var cleanup = {};
    var attach = function (type, callback) {
        // if(activeListeners[type]) {
        //     return
        // }
        var remover = getHandler(type)(callback);
        increaseListeners(type, remover);
    };
    function detach(type) {
        var remaining = decreaseListeners(type);
        if (remaining <= 0) {
            cleanup[type]();
            delete cleanup[type];
        }
    }
    var increaseListeners = function (type, remover) {
        activeListeners[type] = activeListeners[type] ? activeListeners[type] + 1 : 1;
        // Attach another remove functino and execute them all in a row
        cleanup[type] = cleanup[type]
            ? function () {
                cleanup[type]();
                remover();
            }
            : remover;
    };
    var decreaseListeners = function (type) {
        return (activeListeners[type] = activeListeners[type] && activeListeners[type] - 1);
    };
    // public interface
    return {
        add: function (type, callback) {
            attach(type, callback);
        },
        // eslint-disable-next-line no-unused-vars
        remove: function (type, callback) {
            detach(type);
        },
    };
})();
// export default DOMListener
export default DOMListener;
