import split from 'lodash/split';
import first from 'lodash/first';
import last from 'lodash/last';
import { parseQueryString } from './dom/utils';
var isRegex = function (str) { return /^\/.+\/(s|i|m|g)*$/.test(str); };
var patternMatchesUrl = function (pattern, url) {
    if (url === void 0) { url = window.location.href; }
    if (isRegex(pattern)) {
        var parts = pattern.toString().split(/\//);
        var flags = last(parts);
        var searchString = pattern.substring(1, pattern.length - 2);
        return new RegExp(searchString, flags).test(url);
    }
    return pattern === url;
};
/**
 * Removes query and fragment component and returns the rest of URL
 * @param {string} url a string matching to the URL syntax as defined here: https://en.wikipedia.org/wiki/URL
 */
var getUrlWithoutParams = function (url) {
    if (url === void 0) { url = ''; }
    return first(split(url, '?'));
};
export var isUrlAllowed = function (blackList, whiteList, url) {
    if (url === void 0) { url = window.location.href; }
    var pageQueryString = parseQueryString();
    if (pageQueryString === null || pageQueryString === void 0 ? void 0 : pageQueryString.draft)
        return true;
    var urlWithoutParam = getUrlWithoutParams(url);
    if (Array.isArray(whiteList) && whiteList.length) {
        return whiteList.map(function (pattern) { return patternMatchesUrl(pattern, urlWithoutParam); }).some(Boolean);
    }
    if (Array.isArray(blackList)) {
        if (blackList.map(function (pattern) { return patternMatchesUrl(pattern, urlWithoutParam); }).some(Boolean)) {
            return false;
        }
    }
    return true;
};
export var configureUrl = function (journeyConfig) {
    var _a = journeyConfig.blackList, blackList = _a === void 0 ? [] : _a, _b = journeyConfig.whiteList, whiteList = _b === void 0 ? [] : _b;
    return isUrlAllowed(blackList, whiteList);
};
