export var CONVERSIONAL_LAUNCHER_ROOT_ID = 'conversional-launcher-app';
export var CONVERSIONAL_IFRAMES_SCRIPT_ID = 'conversional-iframes-script';
export var CONVERSIONAL_LAUNCHER_CONFIG_DIV_ID = 'conversional-journey';
export var CONVERSIONAL_LAUNCHER_STYLE_ID = 'conversional-launcher-style';
// Theme Classes
export var CONVERSIONAL_PRIMARY_BACKGROUND_CLASS = 'conversional-bg-primary';
export var CONVERSIONAL_PRIMARY_BORDER_COLOR = 'conversional-border-primary';
export var CONVERSIONAL_TEXT_ON_PRIMARY_CLASS = 'conversional-text-on-primary';
export var ICON_SIZE = '40px';
export var CONVERSIONAL_LOADING_OVERLAY_ID = 'conversional-loading-overlay';
