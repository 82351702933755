import { CONVERSIONAL_LAUNCHER_STYLE_ID } from '../constants';
import { appendChild } from '../dom/utils';
import cxs from 'cxs';
cxs.prefix('cv-lwl-');
var LAUNCHER_RAW_CSS = "\n@-webkit-keyframes spin {\n    0% { -webkit-transform: rotate(0deg); }\n    100% { -webkit-transform: rotate(360deg); }\n}\n\n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}\n\n@font-face {\n  font-family: 'Roboto Regular';\n  font-style: 'normal';\n  font-weight: '400';\n  src: url(https://conversional.de/fonts/google/robotoregular.woff2) format('woff2');\n}\n\n\n.cv-loading *[role='loading-indicator'] {\n    display: block;\n}\n\n.cv-loading *[role='launcher-icon'] {\n    display: none;\n}\n\n.cv-loading *[role='avatar-image']{\n  opacity: 0;\n}\n\n,\n".replace(/(\r\n|\n|\r)/gm, '');
export var addLauncherStyles = function () {
    var styleElement = document.createElement('style');
    styleElement.id = CONVERSIONAL_LAUNCHER_STYLE_ID;
    styleElement.innerText = LAUNCHER_RAW_CSS;
    appendChild(document.body, styleElement);
};
