import { getRootLauncher } from '../dom/utils';
import { setLoading } from "../dom/loading";
import { onLauncherClick } from "./actions";
export var addEventListenerToRoot = function (event, handler) {
    getRootLauncher().addEventListener(event, events[handler]);
};
var isLoading = function () { return getRootLauncher().classList.contains('cv-loading'); };
export var events = {
    onRootClick: function (e, retry) {
        var self = events;
        if (!retry && isLoading()) {
            return;
        }
        if (onLauncherClick) {
            setLoading(true);
            onLauncherClick();
            // Delay keeps loading indicator around until iframe fully resized (just for styling matters)
            setLoading(false, { timeout: 15000 });
            return;
        }
        if (!isLoading()) {
            setLoading(true);
        }
        setTimeout(function () {
            if (isLoading())
                self.onRootClick(null, true);
        }, 1000);
    },
};
