import { captureException } from 'conversional-exceptions';
var formatMessage = function (type, message) {
    if (message === void 0) { message = {}; }
    try {
        return JSON.stringify({
            conversional: {
                type: type,
                payload: message
            }
        });
    }
    catch (error) {
        captureException(error, { message: message });
        return '';
    }
};
export default formatMessage;
