var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { trackingConfig as trackingConfigDefault } from 'conversional-environment';
import { getTracker } from 'conversional-analytics';
import { getHub, MESSAGETYPES } from 'conversional-iframe-communication';
// @ts-ignore
import * as Events from 'conversional-journey/events';
// @ts-ignore
import * as configUtils from 'conversional-utils/config';
import { getJourneyId } from '../config';
export function startTracker(config) {
    var id = config.id, _a = config.tracking, tracking = _a === void 0 ? {} : _a, noCookies = config.noCookies, enabled = config.enabled;
    var trackingConfig = trackingConfigDefault;
    trackingConfig.journeyId = id;
    trackingConfig.abTest = configUtils.getAbTest();
    trackingConfig.noCookies = noCookies;
    trackingConfig.enabled = enabled;
    var tracker = getTracker(trackingConfig);
    tracker.updateConfig(tracking);
    return tracker;
}
export function trackLaunchEvents() {
    var tracker = getTracker();
    var event = __assign(__assign({}, Events.INITIAL_PAGE_VISIT), { label: window.location.href });
    tracker.event(event);
}
export function trackUrlParams(urlParams) {
    if (urlParams['utm_campaign']) {
        getTracker().event(__assign(__assign({}, Events.EMAIL_CAMPAIGN_CLICKED), { label: urlParams['utm_campaign'], journeyId: getJourneyId() }));
    }
    // Special parameter that the emailprovider newsletter2go uses
    if (urlParams['n2g_state']) {
        getTracker().event(__assign(__assign({}, Events.EMAIL_PROVIDER_EVENT), { label: urlParams['n2g_state'], journeyId: getJourneyId() }));
    }
    // Track Double Opt In
    // conversional.event.track=DOUBLEOPTIN_EVENT
    var eventName = urlParams['conversional.event.track'];
    if (eventName) {
        Events[eventName] && getTracker().event(__assign(__assign({}, Events[eventName]), { journeyId: getJourneyId() }));
    }
}
export function logCta(cta, label) {
    if (label === void 0) { label = '(not set)'; }
    getHub().publish(MESSAGETYPES.JOURNEY_SET_CTA, { cta: cta, label: label });
}
