// TODO: Can we merge this with iframeTemplates? So that adding a new Iframe just needs one constant to be configured
var PREFIX = 'iframe-conversional-';
export var EXIT_INTENT = "".concat(PREFIX, "exit-intent");
export var EXIT_INTENT_MOBILE = "".concat(PREFIX, "exit-intent-mobile");
export var RESULT = "".concat(PREFIX, "result");
export var ENTRY = "".concat(PREFIX, "entry");
export var LAUNCHER = "".concat(PREFIX, "launcher");
export var INLINE = "".concat(PREFIX, "inline");
export var DYNAMIC = "".concat(PREFIX, "dynamic");
export var TRUST_PULSE = "".concat(PREFIX, "trust-pulse");
export var ARBITRARY = "".concat(PREFIX, "arbitrary");
