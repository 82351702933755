import { widgetUrl } from "./urls.production";
var production = {
    appointmentBookingApiKey: 'live_widget_key_ZP6zuISVKAiEMsbbIKjpVTXTYepIEH2g',
    baseUrl: 'https://api.conversional.de/',
    version: '3',
    apiKey: '',
    questionnaireId: '',
    sentry: {
        widget: "https://8bb380815c9a43c2aa5e3cd221245870@o236458.ingest.sentry.io/1477196",
        bundle: "https://5f72cb7689414643979f5f8a76c731f6@o236458.ingest.sentry.io/4504724861878272"
    },
    widgetUrl: widgetUrl
};
export default production;
