import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
// Predefined event map for specific process steps
var EVENT_MAP = {
    'Process.path-started-inspirationPath': {
        step: 'Step 1: start'
    },
    'Process.path-started-instantAppointmentPath': {
        step: 'Appointment Request'
    },
    'Process.path-completed-inspirationPath': {
        step: 'Step 10: Signup'
    }
};
// Function to modify and map event data to GA4 format
export default function modifyEvent(event, meta) {
    if (meta === void 0) { meta = {}; }
    // Initialize modified event with default values
    var modifiedEvent = {
        track: 'event',
        ga4_name: 'kitchen_stilfinder',
        ga4_data: {} // Empty object for GA4 data payload
    };
    // Create a manipulated string by combining event group, name, and value
    var manipulatedStringEvent = "".concat(event.group, "-").concat(event.name, "-").concat(event.value);
    // Check if the event exists in the EVENT_MAP and assign the corresponding step
    if (EVENT_MAP[manipulatedStringEvent]) {
        modifiedEvent.ga4_data = EVENT_MAP[manipulatedStringEvent];
    }
    // Handle questionKey in meta to generate a custom step for question submissions.
    if (meta.questionKey) {
        var questionKey = meta.questionKey.includes('Answers.')
            ? meta.questionKey.split('Answers.')[1] // Extract key after 'Answers.'
            : meta.questionKey;
        var stepNumber = meta.processStep ? "Step ".concat(meta.processStep, ": ") : ''; // Add process step if available (Should be available, it's just a fallback)
        var step = "".concat(stepNumber).concat(questionKey);
        // Update GA4 data with the step and click item information
        modifiedEvent.ga4_data = {
            step: step,
            click_item: event.value
        };
    }
    // Handle breadcrumb navigation clicks
    if (manipulatedStringEvent.includes('Breadcrumb-goTo')) {
        modifiedEvent.ga4_data = {
            step: 'Breadcrumbs',
            click_item: event.value
        };
    }
    // Handle carousel swipe actions
    if (manipulatedStringEvent.includes('Carousel-swiped')) {
        // Ignore non-relevant carousel events
        if (event.value.includes('a_carousel_kitchen')) {
            modifiedEvent.ga4_name = 'kitchen_stilfinder_widget'; // Update GA4 event name
            modifiedEvent.ga4_data = {
                click_area: 'Gallery',
                action: 'swipe'
            };
        }
    }
    // Handle carousel fullscreen actions
    if (manipulatedStringEvent.includes('Carousel-fullscreen')) {
        modifiedEvent.ga4_name = 'kitchen_stilfinder_widget';
        modifiedEvent.ga4_data = {
            click_area: 'Gallery',
            action: 'enlarge'
        };
    }
    // Handle "liked" actions (The heart icon on carousel)
    if (manipulatedStringEvent.includes('-liked')) {
        modifiedEvent.ga4_name = 'kitchen_stilfinder_widget';
        modifiedEvent.ga4_data = {
            click_area: 'Gallery',
            action: 'save'
        };
    }
    // Handle anchor link clicks for navigation in result
    if (manipulatedStringEvent.includes('AnchorLink-clicked')) {
        modifiedEvent.ga4_name = 'kitchen_stilfinder_widget';
        modifiedEvent.ga4_data = {
            click_area: 'Navigation',
            click_item: event.value.includes("section-")
                ? event.value.split("section-")[1] // Extract section identifier
                : event.value
        };
    }
    // Handle adding carousel items
    if (manipulatedStringEvent.includes('ProfileToggleButton-added')) {
        modifiedEvent.ga4_name = 'kitchen_stilfinder_widget';
        var itemKey = event.value.split(":")[0].trim(); // Extract key from event value
        var itemLabel = event.value.split(":")[1].trim(); // Extract label from event value
        // Differentiate behavior based on the meta.isEquipment flag
        modifiedEvent.ga4_data = meta.isEquipment
            ? {
                click_area: 'Equipment & Functions',
                click_item: "Save::".concat(itemKey, "::").concat(itemLabel)
            } : {
            click_area: itemKey,
            click_item: "Save::".concat(itemLabel)
        };
    }
    // Handle tab navigation clicks
    if (manipulatedStringEvent.includes('ScrollableTabs-clicked')) {
        modifiedEvent.ga4_name = 'kitchen_stilfinder_widget';
        modifiedEvent.ga4_data = {
            click_area: 'Equipment & Functions',
            click_item: "Navigation::".concat(event.value)
        };
    }
    // Handle button clicks specific to result appointment call to action
    if (manipulatedStringEvent.includes("Button-click") && (meta === null || meta === void 0 ? void 0 : meta.place) === "ResultAppointmentCallToActionBar") {
        modifiedEvent.ga4_name = 'kitchen_stilfinder_widget';
        modifiedEvent.ga4_data = {
            click_area: 'Appointment Planning',
            click_item: 'Start Planning'
        };
    }
    // Handle journey close button clicks
    if (manipulatedStringEvent === 'Journey-click-close') {
        modifiedEvent.ga4_name = 'kitchen_stilfinder_widget';
        modifiedEvent.ga4_data = {
            click_area: 'Widget Interaction',
            click_item: 'Close'
        };
    }
    // Handle form submission for the result form
    if (manipulatedStringEvent === 'Form-submitted-ResultForm') {
        modifiedEvent.ga4_name = 'kitchen_stilfinder_widget';
        modifiedEvent.ga4_data = {
            click_area: 'Appointment Planning',
            click_item: "Appointment::".concat(get(meta, 'appointmentType.label'))
        };
    }
    // If GA4 data is empty, return null (nothing to track)
    if (isEmpty(modifiedEvent.ga4_data))
        return null;
    // Return the fully modified event object
    return modifiedEvent;
}
