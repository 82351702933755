import get from "lodash/get";
var URL_PARAM_OPEN_WIDGET = 'openWidget';
function parseQueryString(url) {
    var parseUrl = url || window.location.search;
    return parseUrl
        .substr(1)
        .split('&')
        .reduce(function (obj, item) {
        var parts = item.split('=');
        if (parts[0]) {
            obj[parts[0]] = decodeURI(parts[1]);
        }
        return obj;
    }, {});
}
function parseHref(url) {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
}
function getDraftQueryString() {
    var _a;
    var query = {};
    if (get(window, 'hostUrlParams.draft') || get((_a = window.reduxStore) === null || _a === void 0 ? void 0 : _a.getState(), 'config.external.hostQueryParams.draft'))
        query.draft = 1;
    return query;
}
function isAbsoluteUrl(url) {
    try {
        var parsedUrl = new URL(url);
        return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:';
    }
    catch (e) {
        // If the URL constructor throws an error, it means the URL is not valid or not absolute
        return false;
    }
}
export { parseQueryString, parseHref, getDraftQueryString, isAbsoluteUrl, URL_PARAM_OPEN_WIDGET };
