export var colors = {
    // General
    brand: '#F8DF00',
    light: '#fff',
    error: '#DC3545',
    success: '#28A745',
    // Text
    text: '#111111',
    textLight: '#AAAAAA',
    textDark: '#888888',
    textOnBrand: '#111111',
    textBrandOnWhite: null,
    // Optional value. Can be used for light brand colors on white background, e.g. text on white background that should be brand.
    // Should default to brand color if not set.
    gray90: '#424242',
    // Default to brand color
    textLink: undefined,
    // Elements
    border: '#F4F4F4',
    borderBackgroundRegular: '#EBEBEB',
    borderBackgroundLight: '#000000',
    borderBackgroundDark: '#87888A',
    // Backgrounds
    backgroundDark: '#000000',
    //backgroundRegular: '#f2f2f2',
    backgroundGray: '#e5e5e5',
    backgroundLight: '#fff',
    menuElement: '#666666',
    menuElementLight: '#DDDDDD',
    shadowDark: 'rgba(0, 0, 0, 0.7)',
    shadowSoft: 'rgba(9, 45, 66, 0.08)',
    ///////// Light Theme ///////
    surface: '#f9f9f9',
    surface_light: '#fff',
    surface_dark: '#f4f4f4',
    carbon: '#333',
    carbon_dark: '#111',
    carbon_light: '#aaa',
    carbon_alphaHigh: '#ddd',
    carbon_alphaMedium: '#eee',
    accent: '#fe5e01',
    accent2: '#29a844',
    //////////////////////////////
    ///////// Dark Theme ///////
    // surface: '#282c2f',
    // surface_light: '#31373a',
    // surface_dark: '#23272a',
    // carbon: '#ccc',
    // carbon_dark: '#eee',
    // carbon_light: '#616567',
    // carbon_alphaHigh: '#42484c',
    // carbon_alphaMedium: '#282c2f',
    // accent: '#4caf50',
    // accent2: '#fe0101'
    //////////////////////////////
    ///////// Special Purpose ///////
    muiForceBorder: false,
    //////////////////////////////
};
