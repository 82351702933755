var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// Hint: For custom fonts, refer to .woff2 files. Using other formats like .ttf may cause Chrome to ignore the font.
var fontLight = {
    fontFamily: 'Roboto Light',
    fontStyle: 'normal',
    fontWeight: '300',
    src: "local('Roboto Light'), local('Roboto-Light'), url(https://conversional.de/fonts/google/robotlight.woff2) format('woff2')"
};
var fontRegular = {
    fontFamily: 'Roboto Regular',
    fontStyle: 'normal',
    fontWeight: '400',
    src: "local('Roboto'), local('Roboto-Regular'), url(https://conversional.de/fonts/google/robotoregular.woff2) format('woff2')"
};
var fontMedium = {
    fontFamily: 'Roboto Medium',
    fontStyle: 'normal',
    fontWeight: '500',
    src: "local('Roboto Medium'), local('Roboto-Medium'), url(https://conversional.de/fonts/google/robotomedium.woff2) format('woff2')"
};
var fontBold = {
    fontFamily: 'Roboto Bold',
    fontStyle: 'normal',
    fontWeight: '700',
    src: "local('Roboto Bold'), local('Roboto-Bold'), url(https://conversional.de/fonts/google/robotobold.woff2) format('woff2')"
};
var fontByHand = {
    fontFamily: 'Mali',
    fontStyle: 'normal',
    fontWeight: '400',
    src: "url(https://conversional.de/fonts/google/jrhand.woff) format('woff')"
};
var fonts = {
    light: [fontLight, 'sans-serif'],
    regular: [fontRegular, 'sans-serif'],
    medium: [fontMedium, 'sans-serif'],
    bold: [fontBold, 'sans-serif'],
    byHand: [fontByHand, 'cursive']
};
var sizes = {
    xxl: '1.375rem',
    xxlMobile: '1.125rem',
    xl: '1.125rem',
    xlMobile: '0.875rem',
    l: '1rem',
    lMobile: '0.875rem',
    m: '0.875rem',
    // mMobile: '0.8125rem', // 13px
    mMobile: '0.75rem',
    ms: '0.8125rem',
    msMobile: '0.8125rem',
    s: '0.75rem',
    sMobile: '0.75rem',
    xs: '0.625rem',
    xsMobile: '0.625rem' // 10px (Same as xs)
};
var typo = {
    fonts: __assign({}, fonts),
    sizes: __assign({}, sizes)
};
export default typo;
