var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getHub, MESSAGETYPES, parseMessage } from 'conversional-iframe-communication';
import { getTracker } from 'conversional-analytics';
import { resetParentBodyStyles, styleParentBody } from './styles';
// @ts-ignore
import * as fullscreenUtils from 'conversional-utils/fullscreen';
// @ts-ignore
import * as domUtils from 'conversional-utils/dom';
// @ts-ignore
import * as browserUtil from 'conversional-utils/browser';
import { disableStorage, enableStorage } from "conversional-persistent-storage";
var env = process.env.REACT_APP_JOURNEY_ENV || "development";
/**
 * The message handler defines, what happens with messages that are received from an iframe
 * When an iframe wants to send a message that is distributed to other iframes, it needs to use the message type PUBLISH
 *
 * The hostMessageHandler is not it's own instance. It is only being used from the Iframe Manager.
 */
export var handleMessage = function (message, Iframe) {
    var _a;
    var _b = parseMessage(message), type = _b.type, payload = _b.payload;
    // Do not log debug messages in case messages are too repetitive (like when in setInterval)
    // eslint-disable-next-line no-console
    if (!(payload === null || payload === void 0 ? void 0 : payload.silentLog) && ["development", "testing", "remote"].includes(env))
        console.debug('%chost received message', 'color: #369', message.data);
    if (!type) {
        return;
    }
    var handlers = (_a = {},
        //[MESSAGETYPES.IFRAME_PUBLISH] this happens per default
        _a[MESSAGETYPES.RPC_RESOLVED] = function (payload) { return getHub().publish(MESSAGETYPES.RPC_RESOLVED, payload); },
        _a[MESSAGETYPES.IFRAME_READY] = function () { return Iframe.ready(); },
        _a[MESSAGETYPES.IFRAME_CONTENT_READY] = function () { return Iframe.contentReady(); },
        _a[MESSAGETYPES.IFRAME_SUBSCRIBE] = function () { return Iframe.subscribe(payload); },
        _a[MESSAGETYPES.IFRAME_DESTROY] = function () { return Iframe.destroy(); },
        _a[MESSAGETYPES.IFRAME_STYLE] = function () { return Iframe.style(payload); },
        _a[MESSAGETYPES.IFRAME_STYLE_TARGET_CONTAINER] = function () { return Iframe.styleTargetContainer(payload); },
        _a[MESSAGETYPES.IFRAME_UNSUBSCRIBE] = function () { return Iframe.unsubscribe(payload); },
        _a[MESSAGETYPES.IFRAME_FOCUS] = function () { return Iframe.focus(); },
        _a[MESSAGETYPES.IFRAME_BLUR] = function () { return Iframe.blur(); },
        _a[MESSAGETYPES.TRACK_EVENT] = function () { return getTracker().event(payload); },
        _a[MESSAGETYPES.STORAGE_CONSENT_ACTIVATE] = function () {
            enableStorage();
            Iframe.publish(MESSAGETYPES.STORAGE_CONSENT_ACTIVATE);
        },
        _a[MESSAGETYPES.STORAGE_CONSENT_DEACTIVATE] = function () {
            disableStorage();
            Iframe.publish(MESSAGETYPES.STORAGE_CONSENT_DEACTIVATE);
        },
        _a[MESSAGETYPES.STYLE_BODY] = function () { return styleParentBody(payload); },
        _a[MESSAGETYPES.RESET_STYLE_BODY] = function () { return resetParentBodyStyles(); },
        _a[MESSAGETYPES.IFRAME_ON_SCREEN] = function () { return Iframe.onScreen(); },
        _a[MESSAGETYPES.IFRAME_OFF_SCREEN] = function () { return Iframe.offScreen(); },
        _a[MESSAGETYPES.GEOLOCATION_REQUEST] = function () { return browserUtil.askCurrentPosition(function (coords) {
            getHub().publish(MESSAGETYPES.GEOLOCATION_SUCCESS, coords);
        }, function (error) {
            getHub().publish(MESSAGETYPES.GEOLOCATION_ERROR, error);
        }); },
        _a[MESSAGETYPES.TOGGLE_FULLSCREEN] = function () {
            return fullscreenUtils.toggleFullScreen({
                justMobile: true
            });
        },
        _a[MESSAGETYPES.TRIGGER_PAGE_TITLE] = function () {
            return domUtils.triggerPageTitleChange(payload);
        },
        _a[MESSAGETYPES.ADD_STYLES_TO_HOST] = function () { return domUtils.addStyleTag(payload); },
        _a[MESSAGETYPES.ADD_CLASSNAME_TO_BODY] = function () { return domUtils.addClassNameToBody(payload.className); },
        _a[MESSAGETYPES.REMOVE_CLASSNAME_FROM_BODY] = function () { return domUtils.removeClassNameFromBody(payload.className); },
        _a[MESSAGETYPES.ADD_CLASSNAME_TO_ELEMENTS] = function () { return domUtils.addClassNameToElementsBySelectors(payload.className, payload.selectors); },
        _a[MESSAGETYPES.REMOVE_CLASSNAME_FROM_ELEMENTS] = function () { return domUtils.removeClassNameFromElementsBySelectors(payload.className, payload.selectors); },
        _a.default = function () {
            var type = payload.type, message = __rest(payload, ["type"]);
            Iframe.publish(type, message);
        },
        _a);
    return (handlers[type] || handlers['default'])();
};
