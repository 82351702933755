var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { urlHelpers } from "conversional-api";
import { getTracker } from "conversional-analytics";
// @ts-ignore
import * as Events from 'conversional-journey/events';
import { getHub, iframeCreators, MESSAGETYPES } from "conversional-iframe-communication";
import { extractCommaSeparatedParams, extractDynamicContentParams, URL_PARAM_OPEN_WIDGET, widgetShouldOpen } from "../urlparams";
import { setEarlyLoading, setLoading } from "../dom/loading";
import { getConfig, getJourneyId } from "../config";
import { logCta } from "./tracking";
import { handleCTAClick } from "./callToActions";
var URL_PARAM_RESULT = 'token';
var URL_PARAM_DYNAMIC = 'dynamic';
var URL_PARAM_DYNAMIC_CONTENT = 'contentParams';
var environment = process.env.REACT_APP_JOURNEY_ENV || 'development';
if (['development', 'remote'].includes(environment)) {
    // For ease of opening the result config in development.
    window.showResult = showResult;
}
function showResult(token) {
    getHub().publish(MESSAGETYPES.JOURNEY_SHOW_RESULT, { resultId: token });
}
// Usin the ?dynamic= parameter will show an iframe with custom content
export function showDynamicContent() {
    var _a;
    setEarlyLoading(true);
    var urlParams = extractDynamicContentParams();
    if (!(URL_PARAM_DYNAMIC in urlParams)) {
        return new Promise(function (resolve) { return resolve(); });
    }
    var token = urlParams[URL_PARAM_DYNAMIC];
    var contentParams = (_a = urlParams[URL_PARAM_DYNAMIC_CONTENT]) !== null && _a !== void 0 ? _a : null;
    return iframeCreators.createDynamicIframe(getConfig(), token, contentParams).then(function () {
        setEarlyLoading(false);
    });
}
export function showJourney(urlParams) {
    if (urlParams === void 0) { urlParams = urlHelpers.parseQueryString(); }
    var openWidgetParams = extractCommaSeparatedParams(urlParams[URL_PARAM_OPEN_WIDGET]);
    var openJourneyPayload = {};
    if (openWidgetParams.path) {
        openJourneyPayload.process = {
            pathName: openWidgetParams.path,
        };
    }
    URL_PARAM_RESULT in urlParams
        ? showResult(urlParams[URL_PARAM_RESULT])
        : getHub().publish(MESSAGETYPES.JOURNEY_SHOW, openJourneyPayload);
}
export var processUrlParams = function () {
    var urlParams = urlHelpers.parseQueryString();
    if (widgetShouldOpen(urlParams)) {
        showJourney(urlParams);
        logCta('DirectLink');
    }
};
export var onLauncherClick = function () {
    showJourney();
    getTracker().event(__assign(__assign({}, Events.LAUNCHER_CLICK), { journeyId: getJourneyId() }));
    logCta('Launcher');
};
export var onLauncherClose = function () {
    getTracker().event(__assign(__assign({}, Events.LAUNCHER_CLOSE), { journeyId: getJourneyId() }));
};
export var processCTAClick = function (label) {
    setLoading(true);
    window.conversionalOpenWidgetClicked = true;
    handleCTAClick(label);
    setLoading(false, { timeout: 15000 });
    return;
};
