import cxs from 'cxs';
import first from 'lodash/first';
// @ts-ignore
import envConfig from 'conversional-environment/config';
// @ts-ignore
import { getJourneySettings } from 'conversional-environment/settings';
// @ts-ignore
import * as iframeDomUtils from 'conversional-utils/iframeDom';
import { CONVERSIONAL_LAUNCHER_CONFIG_DIV_ID, CONVERSIONAL_LAUNCHER_ROOT_ID } from '../constants';
import ICONS from '../constants/icons';
var valueIsImportant = function (value) { return typeof value === 'string' && value.includes('!important'); };
var valueIsPixel = function (value, key) {
    return typeof value === 'number' && !['opacity', 'z-index'].includes(key);
};
export var setStyleForRoot = function (key, value) {
    if (valueIsImportant(value)) {
        value = first(value.split(' !important'));
        getRootLauncher().style.setProperty(key, value, 'important');
        return;
    }
    if (valueIsPixel(value, key)) {
        value = value + 'px';
        getRootLauncher().style[key] = value;
    }
};
var configIsAvailable = function () {
    var config = document.getElementById(CONVERSIONAL_LAUNCHER_CONFIG_DIV_ID);
    return Boolean(config);
};
export var runLauncher = function (run) {
    if (configIsAvailable()) {
        return runGetJourneySettings(run);
    }
    attachObserver(run);
};
var runGetJourneySettings = function (run) {
    run(getJourneySettings());
};
var attachObserver = function (run) {
    // Attach observer waiting for configurations that are inserted later
    var observer = new MutationObserver(function () {
        if (configIsAvailable()) {
            observer.disconnect();
            runGetJourneySettings(run);
        }
    });
    observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false,
    });
};
export function getRootLauncher() {
    return document.getElementById(CONVERSIONAL_LAUNCHER_ROOT_ID);
}
export function launcherAvailable() {
    var launcher = getRootLauncher();
    return launcher === null || launcher === void 0 ? void 0 : launcher.classList;
}
export function addStylesToRootLauncher(styles) {
    var classNames = cxs(styles).split(' ');
    classNames.forEach(function (className) {
        return document.getElementById(CONVERSIONAL_LAUNCHER_ROOT_ID).classList.add(className);
    });
}
export var appendToRoot = function (element) {
    if (!getRootLauncher())
        return;
    getRootLauncher().appendChild(element);
};
export var addPreloaderIframe = function () {
    var setContentPayload = {
        conversional: {
            type: 'SET.CONTENT',
            payload: {
                viewConfig: {
                    template: 'PRELOADER',
                },
                journey: {},
                urlParams: {},
            },
        },
    };
    var iframeId = 'iframe-conversional-preloader';
    var iframe = iframeDomUtils.addIframeToDOM({
        id: iframeId,
        src: envConfig.widgetUrl + "?origin=".concat(window.location.origin, "&iframeId=").concat(iframeId),
        targetId: null,
        style: 'width: 0; height:0; display: none;',
    });
    var preloaderReadyCheck = function (message) {
        var _a;
        if (typeof (message === null || message === void 0 ? void 0 : message.data) === 'string' && ((_a = message === null || message === void 0 ? void 0 : message.data) === null || _a === void 0 ? void 0 : _a.includes(iframeId))) {
            setIframeData();
            window.removeEventListener('message', preloaderReadyCheck);
        }
    };
    var setIframeData = function () {
        return iframe.contentWindow.postMessage(JSON.stringify(setContentPayload), '*');
    };
    window.addEventListener('message', preloaderReadyCheck);
};
export var generateIcon = function (iconKey) {
    var icon = ICONS[iconKey];
    var xhr = new XMLHttpRequest();
    var span = document.createElement('span');
    xhr.open('GET', icon);
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            var svg = xhr.responseXML.documentElement;
            svg.setAttribute('width', '45px');
            svg.setAttribute('height', '45px');
            span.appendChild(svg);
        }
    };
    xhr.send();
    return span;
};
var rendered = {};
export function emptyRendered() {
    rendered = {};
}
export function createElement(options) {
    if (options === void 0) { options = { tagName: 'div' }; }
    var id = options.id, _a = options.tagName, tagName = _a === void 0 ? 'div' : _a, className = options.className, innerHTML = options.innerHTML, backgroundColor = options.backgroundColor, uniqueId = options.uniqueId, styles = options.styles, _b = options.attributes, attributes = _b === void 0 ? {} : _b;
    if (uniqueId) {
        if (rendered[uniqueId]) {
            return null;
        }
        rendered[uniqueId] = true;
    }
    var element = options.iconKey ? generateIcon(options.iconKey) : document.createElement(tagName);
    var finalClassName = className || '';
    if (styles) {
        var cxsStyles = Array.isArray(styles) ? styles : [styles];
        cxsStyles
            .filter(function (style) { return style; })
            .forEach(function (style) {
            var className = cxs(style);
            finalClassName += ' ' + className;
        });
    }
    if (finalClassName) {
        element.setAttribute('class', finalClassName);
    }
    if (id)
        element.id = id;
    if (innerHTML)
        element.innerHTML = innerHTML;
    if (backgroundColor)
        element.style.backgroundColor = backgroundColor;
    Object.entries(attributes || {}).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        return element.setAttribute(key, value);
    });
    return element;
}
export function appendChild(parent, child) {
    if (child) {
        parent.appendChild(child);
    }
}
export function addClassToElement(element, className) {
    element && element.classList.add(className);
}
export function removeClassFromElement(element, className) {
    element && element.classList.remove(className);
}
export function parseQueryString(url) {
    var parseUrl = url || window.location.search || '';
    return parseUrl
        .substr(1)
        .split('&')
        .reduce(function (obj, item) {
        var parts = item.split('=');
        obj[parts[0]] = decodeURI(parts[1]);
        return obj;
    }, {});
}
