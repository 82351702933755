var base = 5;
var spaces = {
    // Gaps
    desktop: {
        xs: "".concat(base, "px"),
        s: "".concat(base * 2, "px"),
        m: "".concat(base * 3, "px"),
        mL: "".concat(base * 4, "px"),
        l: "".concat(base * 6, "px"),
        xl: "".concat(base * 8, "px"),
        xxl: "".concat(base * 10, "px"),
        // Spacer
        spacer: '80px',
        sectionSpacer: '180px'
    },
    mobile: {
        xs: "".concat(base, "px"),
        s: "".concat(base * 2, "px"),
        m: "".concat(base * 3, "px"),
        mL: "".concat(base * 4, "px"),
        l: "".concat(base * 5, "px"),
        xl: "".concat(base * 9, "px"),
        spacer: "".concat(base * 15, "px"),
        sectionSpacer: "".concat(base * 25, "px")
    }
};
export default spaces;
