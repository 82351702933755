import { processCTAClick } from "./actions/actions";
export function waitForUserInteraction(callback) {
    var events = ['mousedown', 'mousemove', 'touchstart', 'scroll', 'keydown'];
    /**
     * By default, we will initiate the main app whenever the user interacts with the page.
     * There is an exception when there is a querystring in the URL.
     * If that is present, we directly initiate because we may need the main app to parse the string.
     * */
    if (window.location.search) {
        callback();
        return;
    }
    var initTimeout = setTimeout(function () {
        handler();
    }, 15 * 1000);
    var initialisationCompleted = false;
    function handler() {
        clearTimeout(initTimeout);
        if (initialisationCompleted) {
            return;
        }
        initialisationCompleted = true;
        callback && callback();
        unSubscribeToUserInteraction();
    }
    function subscribeToUserInteraction() {
        events.forEach(function (event) { return document.addEventListener(event, handler); });
    }
    function unSubscribeToUserInteraction() {
        events.forEach(function (event) { return document.removeEventListener(event, handler); });
    }
    subscribeToUserInteraction();
}
var _CTAClicked = null;
export var waitForClient = function (resolve, reject, cycle) {
    if (cycle === void 0) { cycle = 0; }
    if (window.conversionalClient) {
        resolve(window.conversionalClient);
        // Process CTA click once the client is ready
        if (_CTAClicked) {
            processCTAClick(_CTAClicked);
            _CTAClicked = null;
        }
        return;
    }
    if (cycle === 60) {
        reject();
        return;
    }
    setTimeout(function () { return waitForClient(resolve, reject, cycle + 1); }, 500);
};
