import get from 'lodash/get';
export var iconLauncherStyles = function (theme) { return ({
    launcherIconContainer: {
        borderRadius: '50%',
        display: 'flex',
        boxShadow: 'rgba(0, 0, 0, 0.09) 0px 1px 3px, rgba(0, 0, 0, 0.09) -4px 1px 2px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '75px',
        margin: '0',
        width: '75px',
        minWidth: '75px',
        background: get(theme, 'colors.brand'),
        color: get(theme, 'colors.textOnBrand'),
        zIndex: 2,
        ':hover': {
            boxShadow: 'rgba(0, 0, 0, 0.18) 0px 1px 3px, rgba(0, 0, 0, 0.12) -4px 1px 2px',
        },
    },
    launcherIcon: {
        fill: get(theme, 'colors.textOnBrand'),
        marginTop: '-5px',
    },
    launcherTitle: {
        position: 'absolute',
        textAlign: 'center',
        fontSize: '10px',
        borderRadius: '10px',
        bottom: '0px',
        padding: '2px 10px',
        width: 'auto',
        whiteSpace: 'nowrap',
        background: get(theme, 'colors.brand'),
        color: get(theme, 'colors.textOnBrand'),
        zIndex: 3,
    },
}); };
