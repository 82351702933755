var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import localStorageFallback from 'local-storage-fallback';
import unset from 'lodash/unset';
import get from 'lodash/get';
import set from 'lodash/set';
import entries from 'lodash/entries';
import blackList from './blackList';
var STORAGE_KEY = 'conversional-state';
export var STORAGE_EXPIRATION_IN_MILLIS = 360000;
export var STORAGE_CREATED_AT_NAME = 'createdAt';
var questionnaireId;
export var setKey = function (key) {
    questionnaireId = key;
};
export var ENGINES = {
    SESSION_STORAGE: "sessionStorage",
    LOCAL_STORAGE: "localStorage",
};
var DEFAULT_ENGINE = ENGINES.SESSION_STORAGE;
// The keys we are using for storing the data for the application
var COMMONLY_USED_STORAGE_KEYS = ['origin', 'cSessionId', 'lastCta', 'abTest'];
var CONSENT_DISABLED_KEY = 'conversional-storage-consent-disabled';
/**
 * Setting default value to window.sessionStorage causes an error so by default it is localstorage and
 * if we ensure that session storage is available, we will set sessionstorage
 * */
var engines = (_a = {},
    _a[ENGINES.LOCAL_STORAGE] = localStorageFallback,
    _a);
try {
    engines[ENGINES.SESSION_STORAGE] = window.sessionStorage;
}
catch (e) {
    engines[ENGINES.SESSION_STORAGE] = localStorageFallback;
}
var getEngine = function (engine) {
    return engines[engine] || engines[DEFAULT_ENGINE];
};
export var wipe = function () {
    var keys = __spreadArray([], COMMONLY_USED_STORAGE_KEYS, true);
    try {
        keys.push.apply(keys, Object.keys(localStorageFallback).filter(function (key) { return key.includes('conversional'); }));
    }
    catch (e) {
        // Handle exception for cases we can't access local storage
    }
    try {
        keys.push.apply(keys, Object.keys(sessionStorage).filter(function (key) { return key.includes('conversional'); }));
    }
    catch (e) {
        // Handle exception for cases we can't access session storage
    }
    for (var _i = 0, _a = Object.keys(ENGINES); _i < _a.length; _i++) {
        var engineKey = _a[_i];
        var engine = getEngine(ENGINES[engineKey]);
        for (var _b = 0, keys_1 = keys; _b < keys_1.length; _b++) {
            var key = keys_1[_b];
            try {
                engine.removeItem(key);
            }
            catch (e) {
                // Handle exception for cases we can't access engine
            }
        }
    }
};
export var disableStorage = function () {
    wipe();
    setItem(CONSENT_DISABLED_KEY, "1", {}, ENGINES.LOCAL_STORAGE);
};
export var isStorageEnabled = function () { return !getItem(CONSENT_DISABLED_KEY, {}, ENGINES.LOCAL_STORAGE); };
export var enableStorage = function () { return removeItem(CONSENT_DISABLED_KEY, {}, ENGINES.LOCAL_STORAGE); };
export var getItem = function (key, options, engine) {
    if (options === void 0) { options = {
        prefixed: false,
        noAppendix: false,
        ttl: 0
    }; }
    if (engine === void 0) { engine = DEFAULT_ENGINE; }
    var storage = getEngine(engine);
    var saveKey = namespaceKey(key, {
        prefixed: options.prefixed
    });
    var et = storage.getItem(saveKey + '-et');
    // Last condition is to clear out previously incorrect settings of the timestamp which was containing more digits and lead to an invalid date
    if (et && new Date().getTime() >= parseInt(et)) {
        storage.removeItem(saveKey);
        storage.removeItem(saveKey + '-et');
        return null;
    }
    return storage.getItem(saveKey);
};
export var setItem = function (key, value, options, engine) {
    if (options === void 0) { options = {}; }
    if (engine === void 0) { engine = DEFAULT_ENGINE; }
    if (!isStorageEnabled())
        return;
    var storage = getEngine(engine);
    var saveKey = namespaceKey(key, options);
    storage.setItem(saveKey, value);
    if (options.ttl) {
        var expiry = "".concat(new Date().getTime() + Number(options.ttl));
        setItem(saveKey + '-et', expiry, { noAppendix: true, prefixed: false }, engine);
    }
    return saveKey;
};
export var removeItem = function (key, options, engine) {
    if (options === void 0) { options = {}; }
    if (engine === void 0) { engine = DEFAULT_ENGINE; }
    var saveKey = namespaceKey(key, {
        prefixed: options.prefixed
    });
    var storage = getEngine(engine);
    storage.removeItem(saveKey);
    return saveKey;
};
var namespaceKey = function (key, options) {
    if (options === void 0) { options = {}; }
    var appendix = questionnaireId || '';
    return (options.prefixed ? "".concat(STORAGE_KEY, "-") : '') + ((!options.noAppendix && appendix) ? "".concat(key, "-").concat(appendix) : key);
};
export var loadState = function (getCurrentJourneys, captureException) {
    try {
        var serializedState = getItem(STORAGE_KEY);
        if (serializedState === null) {
            return undefined;
        }
        var state = JSON.parse(serializedState);
        var validState = removeExpiredAttributes(state, getCurrentJourneys);
        return validState;
    }
    catch (err) {
        captureException && captureException(err, { place: "LOAD_STATE_FROM_STORAGE" });
        return undefined;
    }
};
export var saveState = function (state) {
    var stateToPersist = filter(state);
    var stateWithExpiryTime = setTimestamp(stateToPersist);
    try {
        var serializedState = JSON.stringify(stateWithExpiryTime);
        setItem(STORAGE_KEY, serializedState);
    }
    catch (err) {
        // Ignore write errors
    }
};
export var filter = function (state) {
    var filteredState = JSON.parse(JSON.stringify(state));
    for (var _i = 0, blackList_1 = blackList; _i < blackList_1.length; _i++) {
        var prop = blackList_1[_i];
        unset(filteredState, prop);
    }
    return filteredState;
};
export var removeExpiredAttributes = function (state, getCurrentJourneys) {
    // Remove results if tokens don't match
    var tokens = entries(get(state, 'config.tokens', {}));
    tokens.forEach(function (_a) {
        var journeyId = _a[0], token = _a[1];
        if (get(state, "result.".concat(journeyId, ".config.alias")) !== token) {
            unset(state, "result.".concat(journeyId));
        }
    });
    // If state is not expired, return it as it is
    if (!state.journey ||
        !state.journey[STORAGE_CREATED_AT_NAME] ||
        !isExpired(state.journey[STORAGE_CREATED_AT_NAME])) {
        return state;
    }
    // Collect the values to keep
    // TODO: UPdate moving from questionnaires to processes!
    var newState = {};
    var journeys = getCurrentJourneys(state);
    entries(journeys).forEach(function (_a) {
        var journeyId = _a[0], journey = _a[1];
        if (journey.hideExitIntent) {
            set(newState, "journey.".concat(journeyId, ".hideExitIntent"), journey.hideExitIntent);
        }
    });
    // @ts-ignore
    return newState; // TODO: The current extraction is not aligned with the expected type
};
export var isExpired = function (timestamp) {
    return new Date().getTime() - timestamp > STORAGE_EXPIRATION_IN_MILLIS;
};
export var setTimestamp = function (state) {
    var _a;
    if (state.journey && !state.journey[STORAGE_CREATED_AT_NAME]) {
        return __assign(__assign({}, state), { journey: __assign(__assign({}, state.journey), (_a = {}, _a[STORAGE_CREATED_AT_NAME] = new Date().getTime(), _a)) });
    }
    return state;
};
var store = {
    getItem: getItem,
    removeItem: removeItem,
    setItem: setItem,
    wipe: wipe,
    disableStorage: disableStorage,
    enableStorage: enableStorage,
    isStorageEnabled: isStorageEnabled
};
export default store;
