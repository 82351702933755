// Dot notation in message strings is used to be compatible with pubsub-js
// Iframe Specifc Events
export var IFRAME_READY = 'IFRAME.READY';
export var IFRAME_CONTENT_READY = 'IFRAME.CONTENT_READY';
export var IFRAME_STYLE = 'IFRAME.STYLE';
export var IFRAME_STYLE_TARGET_CONTAINER = 'IFRAME.STYLE_TARGET_CONTAINER';
export var IFRAME_PUBLISH = 'IFRAME.PUBLISH';
export var IFRAME_SUBSCRIBE = 'IFRAME.SUBSCRIBE';
export var IFRAME_UNSUBSCRIBE = 'IFRAME.UNSUBSCRIBE';
export var IFRAME_DESTROY = 'IFRAME.DESTROY';
export var IFRAME_FOCUS = 'IFRAME.FOCUS';
export var IFRAME_BLUR = 'IFRAME.BLUR';
export var IFRAME_ON_SCREEN = 'IFRAME.ON_SCREEN';
export var IFRAME_OFF_SCREEN = 'IFRAME.OFF_SCREEN';
// Journey Events
export var JOURNEY_SHOW_ENTRY = 'JOURNEY.SHOW_ENTRY';
export var JOURNEY_SHOW_RESULT = 'JOURNEY.SHOW_RESULT';
export var JOURNEY_RESULT_READY = 'JOURNEY.RESULT_READY';
export var JOURNEY_SHOW = 'JOURNEY.SHOW';
export var JOURNEY_CLOSE = 'JOURNEY.CLOSE';
export var JOURNEY_RESTART = 'JOURNEY.RESTART';
export var JOURNEY_SET_CTA = 'JOURNEY.SET_CTA';
export var JOURNEY_CREATE_RESULT = 'JOURNEY.CREATE_RESULT';
// Listener events for subscription
// Sending an ON event will automatically subscribe an iframe to this event, e.g.
/** {
    type: "ON",
    payload: {
        event: "IFRAME_READY"
    }
    will subscribe to the iframe_ready event
}*/
export var ON = 'ON';
export var OFF = 'OFF';
export var ON_EXIT = ON + '.EXIT';
export var OFF_EXIT = OFF + '.EXIT';
export var ON_EXITMOBILE = ON + '.EXITMOBILE';
export var OFF_EXITMOBILE = OFF + '.EXITMOBILE';
// General events
export var STYLE_BODY = 'STYLE.BODY';
export var RESET_STYLE_BODY = 'STYLE.BODY_RESET';
export var TRACK_EVENT = 'TRACK.EVENT';
export var TOGGLE_FULLSCREEN = 'TOGGLE_FULLSCREEN';
export var ADD_STYLES_TO_HOST = 'STYLES.ADD_TO_HOST';
export var ADD_CLASSNAME_TO_BODY = 'ADD_CLASSNAME_TO_BODY';
export var REMOVE_CLASSNAME_FROM_BODY = 'REMOVE_CLASSNAME_FROM_BODY';
export var ADD_CLASSNAME_TO_ELEMENTS = 'ADD_CLASSNAME_TO_ELEMENTS';
export var REMOVE_CLASSNAME_FROM_ELEMENTS = 'REMOVE_CLASSNAME_FROM_ELEMENTS';
// Messages specifically directed from Host to Iframe
export var SET_CONTENT = 'SET.CONTENT';
export var SET_EXTERNAL = 'SET.EXTERNAL';
export var SET_SHARED_STORE = 'SET.SHARED_STORE';
// region RPC Messages
// RPC messages are specifically for public API usage
// RPC messages are namespaces: The Exit Intent Template will listen to all messages starting with RPC.EXIT_INTENT
// region Exit Intent
export var RPC = 'RPC';
export var RPC_RESOLVED = 'RPC.RESOLVED';
export var RPC_DISABLE_EXIT_INTENT = RPC + '.EXIT_INTENT.DISABLE';
export var RPC_ENABLE_EXIT_INTENT = RPC + '.EXIT_INTENT.ENABLE';
export var RPC_OPEN_EXIT_INTENT = RPC + '.EXIT_INTENT.OPEN';
// endregion
// region Journey
export var RPC_OPEN_JOURNEY = JOURNEY_SHOW;
export var RPC_CLOSE_JOURNEY = JOURNEY_CLOSE;
export var RPC_UPDATE_JOURNEY_CONFIGURATION_THEME = RPC + '.JOURNEY.UPDATE_CONFIGURATION_THEME';
export var RPC_UPDATE_JOURNEY_CONFIGURATION_CTA = RPC + '.JOURNEY.UPDATE_CONFIGURATION_CTA';
export var RPC_UPDATE_JOURNEY_CONFIGURATION_ENTRY = RPC + '.JOURNEY.UPDATE_CONFIGURATION_ENTRY';
export var RPC_SET_EXTERNAL = RPC + '.JOURNEY.SET_EXTERNAL';
// endregion
// region Development
export var RPC_CLEAR_CACHE = RPC + '.CLEAR_CACHE';
// endregion
// endregion
// region Preview
export var PREVIEW_READY = 'PREVIEW.READY';
export var CHANGE_CONFIG = 'CONFIG.CHANGE';
export var CHANGE_THEME = 'THEME.CHANGE';
// endregion
export var SEND_LOADING_MESSAGE = 'SEND.LOADING_MESSAGE';
export var TRIGGER_PAGE_TITLE = 'TRIGGER_PAGE_TITLE';
// region Geolocation
export var GEOLOCATION_REQUEST = "GEOLOCATION.REQUEST";
export var GEOLOCATION_SUCCESS = "GEOLOCATION.SUCCESS";
export var GEOLOCATION_ERROR = "GEOLOCATION.ERROR";
// endregion
// region Consent
export var STORAGE_CONSENT_ACTIVATE = 'STORAGE.CONSENT.ACTIVATE';
export var STORAGE_CONSENT_DEACTIVATE = 'STORAGE.CONSENT.DEACTIVATE';
// endregion
export var MODAL_TOGGLE = 'MODAL.TOGGLE';
