var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as tracker from 'conversional-analytics';
// @ts-ignore
import * as callToActionUtils from 'conversional-utils/callToActions';
// @ts-ignore
import * as Events from 'conversional-journey/events';
import { logCta } from './tracking';
import { getJourneyId } from '../config';
import { showJourney } from './actions';
export var CLASSNAME = 'rt-stylecheck';
var getTracker = tracker.getTracker;
export function trackCTAViews() {
    setTimeout(function () {
        callToActionUtils.onCTAShown(trackCTA);
    }, 5000);
}
var trackCTA = function (label) {
    return getTracker().event(__assign(__assign({}, Events.CALLTOACTION_SHOWN), { label: label, journeyId: getJourneyId() }));
};
export var handleCTAClick = function (label) {
    showJourney();
    logCta('callToAction', label); // Persist for conversion tracking
    getTracker().event(__assign(__assign({}, Events.CALLTOACTION_CLICK), { label: label, journeyId: getJourneyId() }));
};
